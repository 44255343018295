import React from "react";
import Div100vh from "react-div-100vh";
import { useDocumentTitle } from "../hooks";
import SignupForm from "../components/SignupForm";

const SignupPage = () => {

  useDocumentTitle("Sign Up - LabTab");

  return (
    <Div100vh className="bg-p-200 flex items-center justify-center">
      <SignupForm />
    </Div100vh>
  );
};

SignupPage.propTypes = {
  
};

export default SignupPage;