import React from "react";
import PropTypes from "prop-types";
import anonymousIcon from "../images/anonymous-user.svg";
import { Link } from "react-router-dom";

const MemberPhoto = props => {

  const { member, children, size, sm, md, isLink, pxSize } = props;
  const s2m = s =>  !children ? 0 : s > 36 ? 5 : s > 24 ? 4 : s > 14 ? 3 : s > 6 ? 2 : 1;
  const imageSrc = member.image ? `${process.env.REACT_APP_FILES}/${member.image}` : anonymousIcon;
  let className = `object-cover rounded-full mr-${s2m(size)}`;
  if (size) className += ` h-${size} w-${size} min-w-${size}`;
  if (size && sm) className += ` sm:h-${sm} sm:w-${sm} sm:min-w-${sm}`;
  if (sm) className += ` sm:mr-${s2m(sm)}`;
  if (size && md) className += ` md:h-${md} md:w-${md} md:min-w-${md}`;
  if (md) className += ` md:mr-${s2m(md)}`;
  const Element = isLink ? Link : "div";

  return (
    <Element
      className={`grid grid-cols-max items-center ${props.className || ""}`}
      to={isLink ? `/members/${member.id}/` : null}
      style={props.style}
    >
      <img className={className} src={imageSrc} alt="" style={pxSize ? {
        width: pxSize, height: pxSize
      } : null} />
      <div>{children}</div>
    </Element>
  );
};

MemberPhoto.propTypes = {
  member: PropTypes.object.isRequired,
  size: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  isLink: PropTypes.bool,
  pxSize: PropTypes.number
};

export default MemberPhoto;