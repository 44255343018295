import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { BlockPicker } from "react-color";
import { CREATE_PROJECT, UPDATE_PROJECT } from "../mutations";
import Button from "./Button";
import { PROJECT, PROJECTS } from "../queries";
import { createErrorObject } from "../forms";
import MemberSelector from "./MemberSelector";
import DeleteProjectModal from "./DeleteProjectModal";

const ProjectForm = props => {

  const { project, allMembers } = props;

  const COLORS = [
    "#1CBCEE", "#374DBF", "#851CEE", "#C41CEE", "#EC3DDB", "#717171", "#222222",
    "#E52020", "#BA0E0E", "#ED5A08", "#EDAD08", "#37BF5D", "#3BAC98", "#067E04",
  ]

  const colorRef = useRef();

  useEffect(() => {
    const onClick = e => {
      if (colorRef.current && !colorRef.current.contains(e.target)) {
        setShowColors(false);
      }
    };
    window.addEventListener("click", onClick);
    return () => window.removeEventListener("click", onClick);
  }, [])

  const [name, setName] = useState(project ? project.name : "");
  const [color, setColor] = useState(project ? project.color : COLORS[0]);
  const [description, setDescription] = useState(project ? project.description : "");
  const [members, setMembers] = useState(project ? project.members.map(m => m.id) : []);
  const [errors, setErrors] = useState({});
  const [showColors, setShowColors] = useState(false);
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const history = useHistory();

  const [createProject, createProjectMutation] = useMutation(CREATE_PROJECT, {
    onCompleted: ({createProject: {project: {id}}}) => {
      history.push(`/projects/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: PROJECTS}],
    awaitRefetchQueries: true
  })

  const [updateProject, updateProjectMutation] = useMutation(UPDATE_PROJECT, {
    onCompleted: ({updateProject: {project: {id}}}) => {
      history.push(`/projects/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {query: PROJECT, variables: {id: project && project.id}}, {query: PROJECTS}
    ]
  })

  const formSubmit = e => {
    e.preventDefault();
    if (project) {
      updateProject({
        variables: {id: project.id, name, color, description, members}
      })
    } else {
      createProject({
        variables: {name, color, description, members}
      })
    }
  }

  return (
    <>
      <form  onSubmit={formSubmit} className={`bg-white rounded-md p-5 w-full max-w-xl md:p-6 ${props.className || ""}`}>
        {!project && <h1 className="text-3xl font-medium text-center mb-6">Create a project</h1>}
        <div className="grid grid-cols-max items-center mb-3 gap-3 relative">
          
          <div ref={colorRef}>
            <div
              className="w-10 h-10 rounded cursor-pointer"
              style={{backgroundColor: color}}
              onClick={() => setShowColors(true)}
            />
            <div className="absolute top-12 z-50" ref={colorRef}>
              {showColors && <BlockPicker
                color={color}
                colors={COLORS}
                width="234px"
                triangle="hide"
                onChange={c => setColor(c.hex)}
                style={{display: "none"}}
              />}
            </div>
          </div>
          <div className="">
            <input
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              className={`text-xl font-medium text-p-600  ${errors.title ? "border-2 border-error-200" : ""}`}
              placeholder="Name"
              required
            />
            {errors.name && <div className="error">{errors.name}</div>}
          </div>
        </div>
        
        <div className="mb-6">
          <textarea
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            className={`h-24 resize-none ${errors.text ? "border-2 border-error-200" : ""}`}
            placeholder="Description"
            required
          />
          {errors.description && <div className="error">{errors.description}</div>}
        </div>

        {!project && <div className="text-sm mb-1 text-gray-600">
          Who will be working on this project?
        </div>}
        <MemberSelector members={allMembers} selected={members} setSelected={setMembers} className="mb-6" />

        <div className={`${project ? "flex justify-between" : ""}`}>
          <Button type="submit" className={`btn-primary ${project ? "" : "ml-auto"} block md:ml-0`} loading={createProjectMutation.loading || updateProjectMutation.loading}>{project ? "Save" : "Create"}</Button>
          {project && (
            <button type="button" className="btn-secondary" onClick={() => setShowDeletionModal(true)}>Delete Project</button>
          )}
        </div>
      </form>
      {showDeletionModal && (
        <DeleteProjectModal showModal={showDeletionModal} setShowModal={setShowDeletionModal} project={project} />
      )}
    </>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  allMembers: PropTypes.array.isRequired
};

export default ProjectForm;