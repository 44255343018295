import React from "react";
import Select from "react-select";
const colors = require("../colors").colors;

const LabtabSelect = props => {
  
  const baseStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: null,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[100],
      fontWeight: 300,
      cursor: "text",
      borderBottomLeftRadius: state.menuIsOpen ? 0 : null,
      borderBottomRightRadius: state.menuIsOpen ? 0 : null,
      borderBottomWidth: state.menuIsOpen ? 0 : 1,
      paddingBottom: state.menuIsOpen ? 1 : 0,
      "&:hover": {
        borderColor: colors.gray[200]
      }
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: state.isFocused ? colors.gray[200] : null,
      "&:active": {
        backgroundColor: colors.gray[200]
      }
    }),
    menu: provided => ({
      ...provided,
      marginTop: 0,
      borderWidth: 1,
      borderColor: colors.gray[200],
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderTopWidth: 0,
      left: 0,
      backgroundColor: colors.gray[100],
      boxShadow: null,
      fontWeight: 300
    }),
  }

  const keys = Object.keys(baseStyles).concat(Object.keys(props.styles || {}));

  const customStyles = keys.reduce((prev, key) => ({
    ...prev, [key]: (provided, state) => ({
      ...(key in baseStyles ? baseStyles[key](provided, state) : {}),
      ...(key in (props.styles || {}) ? props.styles[key](provided, state) : {}),
    })
  }), {})

  return <Select {...props} styles={customStyles} />;
};

LabtabSelect.propTypes = {
  
};

export default LabtabSelect;