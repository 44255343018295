import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { JOIN_LIST } from "../../mutations";
import CornerMessage from "../CornerMessage";

const WaitingListForm = () => {

  const [email, setEmail] = useState("");
  const [canTest , setCanTest] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const [join,] = useMutation(JOIN_LIST, {
    onCompleted: () => {
      setEmail("");
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
    onError: () => setEmail(""),
  })

  const formSubmit = e => {
    e.preventDefault();
    join({variables: {email, canTest}})
  }

  return (
    <div className="bg-white pb-16 sm:pb-24" id="signup">
      <div className="relative sm:py-16">
        <div aria-hidden="true" className="hidden sm:block">
          <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
          <svg className="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
            <defs>
              <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
          </svg>
        </div>
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-p-400 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                <path className="text-p-200 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                <path className="text-p-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-semibold text-white tracking-tight sm:text-4xl">
                  Sign up to the waiting list
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-white">
                    Enter your email address to be informed when LabTab is ready.
                </p>
                <p className="mt-2 mx-auto max-w-2xl text-lg text-white">
                    Or get access earlier by becoming a beta tester.
                </p>
              </div>
              <form className="mt-12 sm:mx-auto sm:max-w-xl sm:flex" onSubmit={formSubmit}>
                <div className="min-w-0 flex-1">
                  <label htmlFor="cta_email" className="sr-only">Email address</label>
                  <input id="cta_email" type="email" value={email} onChange={e => setEmail(e.target.value)} className="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-p-600" placeholder="Enter your email" />
                  <div className="sm:absolute">
                    <input type="checkbox" checked={canTest} onChange={() => setCanTest(!canTest)} className="inline w-max mr-3 mt-4"/>
                    <label className="text-white text-lg mt-2">Also give me early access, I want to be a beta tester</label>
                  </div>
                  
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3 sm:w-48">
                  <button type="submit" className="block w-full rounded-md border border-transparent px-5 py-3 bg-p-300 text-base font-medium text-white shadow hover:bg-p-500 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-p-600 sm:px-4">{canTest ? "Become a beta tester" : "Join waitlist"}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showSuccess && <CornerMessage ttl={4000}>Email added to the waiting list - see you soon!</CornerMessage>}
    </div>
  );
};

WaitingListForm.propTypes = {
  
};

export default WaitingListForm;