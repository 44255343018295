import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_MEMBER, UPDATE_MEMBER } from "../mutations";
import { createErrorObject } from "../forms";
import { MEMBER, MEMBERS } from "../queries";
import DeleteMemberModal from "./DeleteMemberModal";
import FileInput from "./FileInput";
import Button from "./Button";
import ProjectSelector from "./ProjectSelector";

const MemberForm = props => {

  const { member, allProjects } = props;

  const [name, setName] = useState(member ? member.name : "");
  const [position, setPosition] = useState(member ? member.position : "");
  const [projects, setProjects] = useState(member ? member.projects.map(p => p.id) : []);
  const [errors, setErrors] = useState({});
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const fileRef = useRef();
  const history = useHistory();

  const [createMember, createMemberMutation] = useMutation(CREATE_MEMBER, {
    onCompleted: ({createMember: {member: {id}}}) => {
      history.push(`/members/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: MEMBERS}],
    awaitRefetchQueries: true
  })

  const [updateMember, updateMemberMutation] = useMutation(UPDATE_MEMBER, {
    onCompleted: ({updateMember: {member: {id}}}) => {
      history.push(`/members/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {query: MEMBER, variables: {id: member && member.id}}, {query: MEMBERS}
    ]
  })

  const formSubmit = e => {
    e.preventDefault();
    if (member) {
      updateMember({
        variables: {id: member.id, name, position, image: fileRef.current.files[0], projects}
      })
    } else {
      createMember({
        variables: {name, position, image: fileRef.current.files[0], projects}
      })
    }
  }

  return (
    <form onSubmit={formSubmit} className={`bg-white rounded-md p-5 w-full max-w-md sm:max-w-xl md:p-6 ${props.className || ""}`}>
      {member ? (
        <Link className="text-2xl font-medium mb-3 block text-p-500 hover:underline" to={`/members/${member.id}/`}>{member.name}</Link>
      ) : (
        <h1 className="text-2xl font-medium mb-3">Add a lab member</h1>
      )}

      {errors.general && <div className="error">There was an error.</div>}
  
      <div className="md:flex md:items-center md:mb-2">
        <div className="md:flex-grow">
          <div className="mb-3">
            <label htmlFor="name">name</label>
            <div>
              <input
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                autoComplete="name"
                className={`${errors.name ? "border-2 border-error-200" : ""}`}
                required
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
          </div>
          <div className="mb-5">
            <label htmlFor="position">position</label>
            <div>
              <input
                id="position"
                value={position}
                onChange={e => setPosition(e.target.value)}
                className={`${errors.position ? "border-2 border-error-200" : ""}`}
                required
              />
              {errors.position && <div className="error">{errors.position}</div>}
            </div>
          </div>
        </div>
        <div className="mx-auto w-min mb-3 md:mx-0 md:ml-8 md:mb-0">
          <div>
            {errors.image && <div>{errors.image}</div>}
            <FileInput innerRef={fileRef} initial={member && member.image} />
          </div>
        </div>
      </div>

      <label>projects</label>
      <ProjectSelector
        projects={allProjects}
        selected={projects}
        setSelected={setProjects}
        className="mb-6"
      />

      
      <div className={`${member ? "flex justify-between" : ""}`}>
        <Button type="submit" className={`btn-primary ${member ? "" : "ml-auto"} block md:ml-0`} loading={createMemberMutation.loading || updateMemberMutation.loading}>{member ? "Save" : "Create"}</Button>
        {member && (
          <div>
            <button type="button" className="btn-secondary" onClick={() => setShowDeletionModal(true)}>Delete Member</button>
            {showDeletionModal && (
              <DeleteMemberModal showModal={showDeletionModal} setShowModal={setShowDeletionModal} member={member} />
            )}
          </div>
        )}
      </div>
    </form>
  );
};

MemberForm.propTypes = {
  member: PropTypes.object
};

export default MemberForm;