import React from "react";
import PropTypes from "prop-types";
import Meeting from "./Meeting";
import MemberPhoto from "./MemberPhoto";
import MeetingsTimeline from "./MeetingsTimeline";

const MeetingsInterface = props => {

  const { meetings, members, perMember, timelineView } = props;

  if (!perMember && (!timelineView || meetings.length <= 1)) {
    return (
      <div className="grid gap-5 grid-cols-96">
        {meetings.map(meeting => (
          <Meeting key={meeting.id} meeting={meeting} showMember={true} />
        ))}
      </div>
    )
  }

  if (perMember && (!timelineView || meetings.length <= 1)) {
    const lastMemberId = members.length && members[members.length - 1].id;

    return (
      <div className="grid grid-cols-max gap-8 overflow-scroll no-scroll items-start -mr-5 -ml-5 pl-5 w-max">
        {members.map(member => (
          <div className="grid gap-5 w-96" key={member.id}>
            <MemberPhoto member={member} size={14} isLink={true}>
              <div className="text-xl">{member.name}</div>
            </MemberPhoto>
            {meetings.filter(meeting => meeting.members.map(m => m.id).includes(member.id)).map(meeting => (
              <Meeting className={member.id === lastMemberId ? "mr-5" : ""} meeting={meeting} key={meeting.id} showMember={false} />
            ))}
          </div>
        ))}
      </div>
    )
  }

  if (timelineView) {
    const startTimestamp = meetings[0].timestamp;
    const endTimestamp = meetings[meetings.length - 1].timestamp;
    const pixelsPerDay = 65;

    if (!perMember) {
      return (
        <div className="overflow-scroll no-scroll -mr-5 -ml-5 pl-5">
          <MeetingsTimeline
            meetings={meetings}
            startTimestamp={startTimestamp}
            endTimestamp={endTimestamp}
            pixelsPerDay={pixelsPerDay}
          />
        </div>
      )
    }

    if (perMember) {
      return (
        <div className="grid gap-16 overflow-scroll no-scroll items-start -mr-5 -ml-5 pl-5">
          {members.map(member => (
            <MeetingsTimeline
              meetings={meetings.filter(meeting => meeting.members.map(m => m.id).includes(member.id))}
              member={member}
              startTimestamp={startTimestamp}
              endTimestamp={endTimestamp}
              pixelsPerDay={pixelsPerDay}
              key={member.id}
            />
          ))}
        </div>
      )
    }
  }
}

MeetingsInterface.propTypes = {
  meetings: PropTypes.array.isRequired,
  perMember: PropTypes.bool.isRequired,
  timelineView: PropTypes.bool.isRequired,
};

export default MeetingsInterface;