import React, { useState } from "react";
import PropTypes from "prop-types";
import plusIcon from "../images/plus.svg";

const FileInput = props => {

  const { required, innerRef, initial } = props;
  const id = Math.random().toString().slice(2);
  const [,setFileName] = useState("");

  const fileAdded = innerRef.current && innerRef.current.files.length > 0;
  const showInitial = initial && !fileAdded;
  const showImage = fileAdded || initial;

  const clearImage = () => {
    innerRef.current.value = "";
    setFileName("");
  }



  return (
    <div className={`w-28 h-28 ${props.className || ""}`}>
      <label htmlFor={id} className="bg-gray-200 text-gray-500 mb-1 w-28 h-28 rounded-full flex justify-center items-center text-7xl leading-1 overflow-hidden cursor-pointer hover:bg-gray-300 hover:text-gray-600">
        {showImage ? (
          <img
            className="h-full w-full object-cover cursor-pointer opacity-95 hover:opacity-100"
            src={showInitial ? `${process.env.REACT_APP_FILES}/${initial}` : URL.createObjectURL(innerRef.current.files[0])}
            alt=""
          />
        ) : <img src={plusIcon} alt="" />}
      </label>
      <input
        type="file"
        id={id}
        required={required}
        ref={innerRef}
        className="hidden"
        onChange={e => setFileName(e.target.value)}
      />
      {showImage ? (
        <div className="text-gray-700 text-center text-sm cursor-pointer" onClick={clearImage}>Clear</div>
      ) : <div className="text-sm opacity-0">.</div>}
    </div>
  );
};

FileInput.propTypes = {
  required: PropTypes.bool,
  innerRef: PropTypes.object,
  initial: PropTypes.string
};

export default FileInput;