import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Base from "./Base";
import Meeting from "../components/Meeting";
import { MEETINGS } from "../queries";
import { usePageWidth, useDocumentTitle } from "../hooks";
import MeetingsInterface from "../components/MeetingsInterface";
import { separateMeetings } from "../utils";
import MeetingsOptions from "../components/MeetingsOptions";
import MemberPhoto from "../components/MemberPhoto";

const MeetingsPage = props => {

  useDocumentTitle("Meetings - LabTab");

  const { future } = props;
  const [perMember, setPerMember] = useState(true);
  const [timelineView, setTimelineView] = useState(false);
  const width = usePageWidth();
  const { loading, data } = useQuery(MEETINGS);
  
  if (loading) return <Base loading={true} />

  const meetings = separateMeetings(data.user.meetings)[future ? 1 : 0];
  const members = meetings.reduce((prev, curr) => [
    ...prev, ...curr.members.filter(m => !prev.map(m_ => m_.id).includes(m.id))
  ], []);
  
  return (
    <Base>
      <MeetingsOptions
        perMember={perMember} setPerMember={setPerMember} showPerMember={true}
        timelineView={timelineView} setTimelineView={setTimelineView} showTimelineView={width >= 640 && meetings.length > 1}
        future={future}
      />
      
      {width < 640 && !perMember && (
        <div className="grid gap-5">
          {meetings.map(meeting => <Meeting meeting={meeting} key={meeting.id} showMember={true} />)}
        </div>
      )}

      {width < 640 && perMember && (
        <div className="grid gap-10">
          {members.map(member => (
            <div className="grid gap-5" key={member.id}>
              <MemberPhoto member={member} size={12} isLink={true}>
                <div className="text-lg">{member.name}</div>
              </MemberPhoto>
              {meetings.filter(meeting => meeting.members.map(m => m.id).includes(member.id)).map(meeting => <Meeting meeting={meeting} key={meeting.id} showMember={false} />)}
            </div>
          ))}
        </div>
      )}

      {width >= 640 && (
        <MeetingsInterface
          meetings={meetings} members={members}
          perMember={perMember} timelineView={timelineView}
        />
      )}
    </Base>
  )
  
  



  


  /* if (!meetings.length) {
    return (
      <Base>
        <div className="no-data">
          You haven't added any lab meetings yet - <br />would you like
          to <Link to="/meetings/new/"> add one</Link>?
        </div>
      </Base>
    )
  }
  
  const membersWithMeetings = members.map(member => ({
    ...member, meetings: meetings.filter(m => m.members.map(member_ => member_.id).includes(member.id))
  })).filter(member => member.meetings.length);

  return (
    <Base>
      <div className="flex flex-col items-center mb-5 max-w-6xl sm:flex-row sm:items-center sm:justify-between">
        <div>
          <BinaryToggle
            trueText={"Per Member"}
            falseText={"All Members"}
            value={perMember}
            setValue={setPerMember}
          />
          {width > 639 && <BinaryToggle
            trueText={"Timeline View"}
            falseText={"List View"}
            value={timelineView}
            setValue={setTimelineView}
          />}
        </div>
        <Link to="/meetings/new/" className="btn-primary py-2 w-max mt-3 sm:mt-0">New Meeting</Link>
      </div>

      {false ? <MeetingsInterface meetings={meetings} width={width} perMember={perMember} timelineView={timelineView} /> : perMember ? (
        <div className="grid gap-3">
          {membersWithMeetings.map(member => (
            <div className="mb-6" key={member.id}>
              <div className="mb-3 text-2xl">{member.name}</div>
              <div className="grid gap-3">
                {member.meetings.map(meeting => <Meeting meeting={meeting} key={meeting.id} showMember={false} />)}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="grid gap-3">
          {meetings.map(meeting => <Meeting meeting={meeting} key={meeting.id} showMember={true} />)}
        </div>
      )}
      
    </Base>
  ); */
};

MeetingsPage.propTypes = {
  
};

export default MeetingsPage;