import React, { useState } from "react";
import { Route } from "react-router";
import { BrowserRouter, Switch } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/client";
import { makeClient } from "../api";
import { UserContext } from "../contexts";
import { TOKEN, USER } from "../queries";
import RingLoader from "react-spinners/RingLoader";
import HomePage from "../pages/HomePage";
import LandingPage from "../pages/LandingPage";
import LoginPage from "../pages/LoginPage";
import SettingsPage from "../pages/SettingsPage";
import MembersPage from "../pages/MembersPage";
import MemberPage from "../pages/MemberPage";
import EditMemberPage from "../pages/EditMemberPage";
import NewMemberPage from "../pages/NewMemberPage";
import MeetingsPage from "../pages/MeetingsPage";
import MeetingPage from "../pages/MeetingPage";
import EditMeetingPage from "../pages/EditMeetingPage";
import NewMeetingPage from "../pages/NewMeetingPage";
import PageNotFound from "../pages/PageNotFound";
import Div100vh from "react-div-100vh";
import SignupPage from "../pages/SignupPage";
import ProjectsPage from "../pages/ProjectsPage";
import ProjectPage from "../pages/ProjectPage";
import NewProjectPage from "../pages/NewProjectPage";
import EditProjectPage from "../pages/EditProjectPage";
import LogRocket from "logrocket";
LogRocket.init("kua6lj/labtab");

const client = makeClient();

const App = () => {

  // Keep track of whether there is a logged in user
  const [user, setUser] = useState(null);

  // Send request for access token
  const tokenQuery = useQuery(TOKEN, {client, pollInterval: 1000 * 60 * 2});

  // If the token query has fired at least once, and log-in status is unknown,
  // try to get user
  useQuery(USER, {
    client,
    skip: tokenQuery.loading || user !== null,
    onCompleted: data => setUser(data.user),
    onError: () => setUser(false)
  })

  // While token request loading, or log-in status is unknown, show loading page
  const colors = require("../colors").colors;
  if (tokenQuery.loading || user === null) {
    return <Div100vh className="flex justify-center items-center"><RingLoader color={colors.p["300"]}/></Div100vh>
  }

  // Otherwise return the normal app with the log-in status set
  return (
    <ApolloProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              {user ? <HomePage /> : <LandingPage />}
            </Route>
            <Route path="/login/" exact>
              <LoginPage />
            </Route>
            <Route path="/signup/" exact>
              <SignupPage />
            </Route>

            {user && <Route path="/settings/" exact>
              <SettingsPage />
            </Route>}
            {user && <Route path="/members/" exact>
              <MembersPage />
            </Route>}
            {user && <Route path="/members/new/" exact>
              <NewMemberPage />
            </Route>}
            {user && <Route path="/members/:id/" exact>
              <MemberPage />
            </Route>}
            {user && <Route path="/members/:id/edit/" exact>
              <EditMemberPage />
            </Route>}
            {user && <Route path="/meetings/" exact>
              <MeetingsPage future={false} />
            </Route>}
            {user && <Route path="/meetings/future/" exact>
              <MeetingsPage future={true} />
            </Route>}
            {user && <Route path="/meetings/new/" exact>
              <NewMeetingPage />
            </Route>}
            {user && <Route path="/meetings/:id/" exact>
              <MeetingPage />
            </Route>}
            {user && <Route path="/meetings/:id/edit/" exact>
              <EditMeetingPage />
            </Route>}
            {user && <Route path="/projects/" exact>
              <ProjectsPage />
            </Route>}
            {user && <Route path="/projects/new/" exact>
              <NewProjectPage />
            </Route>}
            {user && <Route path="/projects/:id/edit/" exact>
              <EditProjectPage />
            </Route>}
            {user && <Route path="/projects/:id/" exact>
              <ProjectPage />
            </Route>}
            <Route><PageNotFound /></Route>
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </ApolloProvider>
  )
}

App.propTypes = {
    
};

export default App;
