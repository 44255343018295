import React from "react";
import onboarding from "../../images/onboarding.png";
import {
    ArrowCircleRightIcon,
    ClipboardListIcon,
    PresentationChartLineIcon,
    ClockIcon,
    TemplateIcon,
    ShieldCheckIcon,
  } from "@heroicons/react/outline";

const Benefits = () => {

  const text = [
    { name: "Up and running in 5 minutes", icon: ArrowCircleRightIcon, text:"No wasting time configuring things - you can get set up with LabTab in literally 5 minutes." },
    { name: "Save time between meetings", icon: PresentationChartLineIcon,  text:"Quickly review past meeting notes and tasks so you can go into each meeting prepared." },
    { name: "Secure, private, backed up", icon: ShieldCheckIcon, text:"All your data is encrypted, GDPR-compliant and backed up so you won't ever lose files." },
    { name: "Intelligent deadline scheduling", icon: ClockIcon,  text:"Link your tasks with your papers, talks and grants, for useful reminders of what needs doing when, minimising last-minute stress." },
    { name: "All your lab info, in one place", icon: ClipboardListIcon,  text:"Search and view all your past meetings, notes, papers, lab members and projects, from wherever you are." },
    { name: "Clean, modern design" , icon: TemplateIcon, text:"Lab management apps don't have to look like they're from the 90s - LabTab looks great and works on mobile, tablet and desktop" },
  ]
  
  return (
    <>     
      <div className="relative bg-gray-100 mb-8 pt-16 sm:pt-24 lg:pt-32">
        <div className="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-8 lg:max-w-7xl">
          <div>
            <h2 className="text-base font-semibold tracking-wider text-p-500 uppercase">Easy to set up</h2>
            <p className="mt-2 text-3xl font-semibold text-gray-900 tracking-tight sm:text-4xl">
                Simple lab management, without the hassle.
            </p>
            <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700">
              LabTab is the kind of tool you can integrate into your routine slowly over time - it requires no time investment up-front and just works around you. 
            </p>
          </div>
          <div className="mt-12">
            <img
              className="rounded-md shadow-xl 1"
              src={onboarding}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="relative bg-white py-16 sm:py-24 lg:py-32">
        <div className="mx-auto  max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="text-base font-semibold tracking-wider text-t-500 uppercase">A lab management tool designed for you</h2>
          <p className="mt-2 text-3xl font-semibold text-gray-900 tracking-tight sm:text-4xl">
            One single app, built for group leaders.
          </p>
              <p className="mt-5 max-w-prose mx-auto text-xl text-gray-700">Turbocharge your academic productivity - supervise more effectively, get papers out faster, and keep on top of deadlines, without giving up more free time.</p>
          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {text.map((feature) => (
                <div key={feature.name} className="pt-6 ">
                  <div className="flow-root bg-gray-100 rounded-lg px-6 pb-8">
                    <div className="-mt-6">
                      <div>
                        <span className="inline-flex items-center justify-center p-3 bg-p-400 rounded-md shadow-lg">
                          <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </div>
                      <h3 className="mt-8 text-lg font-semibold text-gray-800 ">{feature.name}</h3>
                      <p className="mt-5 text-base text-gray-700">
                        {feature.text}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </> 
  );
};

Benefits.propTypes = {
  
};

export default Benefits;