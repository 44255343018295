import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { MEMBER } from "../queries";
import PageNotFound from "./PageNotFound";
import Meeting from "../components/Meeting";
import penIcon from "../images/edit.svg";
import MemberPhoto from "../components/MemberPhoto";
import { detect404 } from "../forms";
import { separateMeetings } from "../utils";
import MetSummary from "../components/MetSummary";
import ProjectLink from "../components/ProjectLink";

const MembersPage = () => {

  const memberId = useRouteMatch("/members/:id/").params.id;

  const { loading, data, error } = useQuery(MEMBER, {variables: {id: memberId}});

  useDocumentTitle(data ? `${data.user.member.name} - LabTab` : "LabTab");

  if (loading) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const member = data.user.member;
  const [pastMeetings, futureMeetings] = separateMeetings(member.meetings);

  return (
    <Base>
      <div className="bg-white rounded py-4 px-5 mx-auto max-w-4xl relative sm:px-6 sm:py-5 md:px-7 md:py-6">

        <div className="flex justify-between items-start mb-8 flex-col md:flex-row">
          <MemberPhoto member={member} size={20} sm={24} md={36} lg={48} className="mb-4 md:mr-4 md:mb-0">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5l font-medium">{member.name}</h1>
            <div className="text-lg sm:text-xl md:text-2xl lg:text-3xl mb-1">{member.position}</div>
            <MetSummary member={member} className="sm:text-lg" />
          </MemberPhoto>
          
          <div className="grid md:mt-8">
            {member.projects.map(project => (
              <ProjectLink project={project} reversed={true} key={project.id} className="-mr-2" />
            ))}
          </div>
        </div>

        <Link to={`/members/${member.id}/edit/`} className="absolute top-4 right-4 w-5">
          <img  className="opacity-90 hover:opacity-100" src={penIcon} alt="" />
        </Link>

        {futureMeetings.length > 0 && (
          <div>
            <h2 className="text-gray-800 text-xl mb-2">Future Meetings</h2>
            <div className="grid grid-cols-max gap-5 overflow-scroll no-scroll pb-1 mb-5 -mx-5 px-5 sm:-mx-6 sm:px-6 md:-mx-7 md:px-7">
              {futureMeetings.map(meeting => (
                <Meeting
                  meeting={meeting} key={meeting.id}
                  className="w-80 h-full inline-block bg-gray-100 hover:bg-gray-200"
                />
              ))}
              <div className="w-0 -ml-5 pl-5 sm:-ml-6 sm:pl-6 md:-ml-7 md:pl-7" />
            </div>
          </div>
        )}

        {pastMeetings.length > 0 && (
          <div>
            <h2 className="text-gray-800 text-xl mb-2">Past Meetings</h2>
            <div className="grid grid-cols-max gap-5 overflow-scroll no-scroll pb-1 mb-5 -mx-5 px-5 sm:-mx-6 sm:px-6 md:-mx-7 md:px-7">
              {pastMeetings.map(meeting => (
                <Meeting
                  meeting={meeting} key={meeting.id}
                  className="w-80 h-full inline-block bg-gray-100 hover:bg-gray-200"
                />
              ))}
              <div className="w-0 -ml-5 pl-5 sm:-ml-6 sm:pl-6 md:-ml-7 md:pl-7" />
            </div>
          </div>
        )}

        <Link to={`/meetings/new?member=${member.id}`} className="btn-primary mt-4 block w-max">New Meeting</Link>
      </div>
    </Base>
  );
};

MembersPage.propTypes = {
  
};

export default MembersPage;