import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { useDocumentTitle } from "../hooks";
import { HOME } from "../queries";
import Base from "./Base";
import Meeting from "../components/Meeting";

const HomePage = () => {

  useDocumentTitle("LabTab");

  const { loading, data } = useQuery(HOME);

  if (loading) return <Base loading={true} />

  const hours = new Date().getHours();
  const time = hours < 5 ? "Evening" : hours < 12 ? "Morning" : hours < 17 ? "Afternoon" : "Evening";

  const tz = moment.tz.guess();
  const now = new Date().getTime() / 1000;
  const dayEnd = moment.tz(tz).endOf("day").unix();
  const weekEnd = moment.tz(tz).endOf("week").unix();
  const meetingsToday = data.user.meetings.filter(m => m.timestamp > now && m.timestamp <= dayEnd);
  const meetingsThisWeek = data.user.meetings.filter(m => m.timestamp > now && m.timestamp <= weekEnd);
  const meetings = (
    meetingsToday.length ? meetingsToday : meetingsThisWeek.length ? meetingsThisWeek : []
  )
  meetings.reverse();


  return (
    <Base>
      <div className="text-4xl font-medium text-gray-900 mb-8">Good {time}</div>

      <div>
        <div className={`mb-5 text-xl text-gray-800 ${meetings.length ? "font-medium" : ""}`}>
          {meetingsToday.length ? "Today's Meetings" : meetingsThisWeek.length ? "This week's meetings" : "No upcoming meetings this week"}
        </div>
        <div className="grid gap-5 sm:grid-cols-80">
          {meetings.slice(0, 3).map(meeting => (
            <Meeting
              meeting={meeting} key={meeting.id} hideText={true} showMember={true}
              className="max-w-lg"
            />
          ))}  
        </div>
      </div>

    </Base>
  );
};

HomePage.propTypes = {
  
};

export default HomePage;