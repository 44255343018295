import React from "react";
import PropTypes from "prop-types";
import MemberPhoto from "./MemberPhoto";
import { Link } from "react-router-dom";

const Project = props => {

  const { project, isLink } = props;

  const Element = isLink ? Link : "div";

  return (
    <Element
      to={`/projects/${project.id}/`}
      className={`block bg-white rounded-md px-10 py-3 text-center shadow ${isLink ? "hover:bg-gray-100" : ""} ${props.className || ""}`}
      style={{borderTop: `14px solid ${project.color}`}}
    >
      <div className="font-medium text-2xl mb-2">{project.name}</div>
      <div className="text-sm font-light mb-4">{project.description}</div>
      <div className={`flex flex-wrap justify-center ${project.members.length > 4 ? "-mr-2 -mb-2" : "-mr-3 -mb-3"}`}>
        {project.members.map(member => (
          <MemberPhoto
            key={member.id}
            member={member}
            size={project.members.length > 4 ? 6 : 10}
            className={project.members.length > 4 ? "mr-2 mb-2" : "mr-3 mb-3"}
            isLink={!isLink}
          />
        ))}
      </div>
    </Element>
  );
};

Project.propTypes = {
  project: PropTypes.object.isRequired
};

export default Project;