import React from "react";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import ProjectForm from "../components/ProjectForm";
import { POSSIBLE_MEMBERS } from "../queries";

const NewProjectPage = () => {

  useDocumentTitle("New Project - LabTab");

  const { loading, data } = useQuery(POSSIBLE_MEMBERS);

  if (loading) return <Base loading={true} />

  const members = data.user.members;

  return (
    <Base center={true}>
      <ProjectForm allMembers={members} />
    </Base>
  );
};

NewProjectPage.propTypes = {
  
};

export default NewProjectPage;