import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { DELETE_PROJECT } from "../mutations";
import { PROJECTS, MEMBERS, MEETINGS } from "../queries";
import Modal from "./Modal";
import Button from "./Button";

const DeleteProjectModal = props => {

  const { showModal, setShowModal, project } = props;
  const history = useHistory();

  const [deleteProject, deleteProjectMutation] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      history.push("/projects/");
    },
    awaitRefetchQueries: true,
    refetchQueries: [{query: PROJECTS}, {query: MEMBERS}, {query: MEETINGS}]
  })

  if (!showModal) return <div />

  return (
    <Modal
      title="Delete Project?"
      subtitle={project.name}
      text="Are you sure you want to delete this project? This can't be undone."
      setShowModal={setShowModal}
    >
      <Button className="btn-primary" onClick={() => deleteProject({variables: {id: project.id}})} loading={deleteProjectMutation.loading}>Delete</Button>
      <button className="btn-secondary ml-3" onClick={() => setShowModal(false)}>Keep</button>
    </Modal>
  );
};

DeleteProjectModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired
};

export default DeleteProjectModal;