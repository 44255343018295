import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { MEETING, POSSIBLE_MEMBERS, POSSIBLE_PROJECTS } from "../queries";
import PageNotFound from "./PageNotFound";
import MeetingForm from "../components/MeetingForm";
import { detect404 } from "../forms";

const EditMeetingPage = () => {

  const meetingId = useRouteMatch("/meetings/:id/").params.id;

  const { loading, data, error } = useQuery(MEETING, {variables: {id: meetingId}});
  const { data: membersData } = useQuery(POSSIBLE_MEMBERS);
  const { data: projectsData } = useQuery(POSSIBLE_PROJECTS);

  useDocumentTitle(data ? `${data.user.meeting.title} - LabTab` : "LabTab");

  if (loading || !membersData || !projectsData) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const meeting = data.user.meeting;

  return (
    <Base center={true}>
      <MeetingForm
        meeting={meeting}
        allMembers={membersData.user.members}
        allProjects={projectsData.user.projects}
      />
    </Base>
  );
};

EditMeetingPage.propTypes = {
  
};

export default EditMeetingPage;