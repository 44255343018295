import React from "react";
import { useDocumentTitle } from "../hooks";
import Div100vh from "react-div-100vh";
import Hero from "../components/landing/Hero";
import Features from "../components/landing/Features";
import Benefits from "../components/landing/Benefits";
import WaitingListForm from "../components/landing/WaitingListForm";
import Footer from "../components/landing/Footer";

const LandingPage = () => {

  useDocumentTitle("Manage your Lab - LabTab");

  return (
    <Div100vh>
      <Hero />
      <Features />
      <Benefits />
      <WaitingListForm />
      <Footer />
    </Div100vh>
  );
};

LandingPage.propTypes = {
  
};

export default LandingPage;