import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { TOKEN } from "./queries";

export const makeClient = () => {
  /**
   * Creates an apollo client with automatic token insertion, and catchall
   * error handling.
   */

  const terminalLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND}/graphql`,
    headers: {"keep-alive": "true"}, credentials: "include",
    fetchPolicy: "network-only"
  });

  const authLink = new ApolloLink((operation, forward) => {
    const { cache } = operation.getContext();
    let token;
    try {
      const cacheValue = cache.readQuery({query: TOKEN});
      token = cacheValue.accessToken;
    } catch {
      token = null;
    }
    operation.setContext(({ headers }) => ({ headers: {
      authorization: token, ...headers
    }}));
    return forward(operation);
  });

  const link = ApolloLink.from([authLink, terminalLink]);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          user: {
            merge: (existing, incoming) => incoming
          }
        }
      },
      UserType: {
        fields: {
          projects: {
            merge: (existing, incoming) => incoming
          },
          members: {
            merge: (existing, incoming) => incoming
          },
          meetings: {
            merge: (existing, incoming) => incoming
          }
        }
      },
      LabMemberType: {
        fields: {
          projects: {
            merge: (existing, incoming) => incoming
          },
          meetings: {
            merge: (existing, incoming) => incoming
          }
        }
      }
    }
  })

  return new ApolloClient({
    link: link, cache, credentials: "include",
    defaultOptions: {
      watchQuery: {
        fetchPolicy:"network-only",
      },
    }
  });
}
