import React from "react";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import MeetingForm from "../components/MeetingForm";
import { POSSIBLE_MEMBERS, POSSIBLE_PROJECTS } from "../queries";
import { useLocation } from "react-router";

const NewMeetingPage = () => {

  useDocumentTitle("New Meeting - LabTab");

  const { loading, data } = useQuery(POSSIBLE_MEMBERS);
  const projectQuery = useQuery(POSSIBLE_PROJECTS, {skip: loading});
  const location = useLocation();

  if (loading || projectQuery.loading) return <Base loading={true} />

  const members = data.user.members;
  const projects = projectQuery.data.user.projects;
  const memberId = new URLSearchParams(location.search).get("member");

  return (
    <Base center={true}>
      <MeetingForm allMembers={members} allProjects={projects} memberId={memberId} />
    </Base>
  );
};

NewMeetingPage.propTypes = {
  
};

export default NewMeetingPage;