import React from "react";
import PropTypes from "prop-types";
import MemberPhoto from "./MemberPhoto";
import LabtabSelect from "./LabtabSelect";

const MemberSelector = props => {

  const { members, selected, setSelected } = props;

  const memberOptions = members.filter(m => !selected.includes(m.id)).map(member => ({
    value: member.id, label: (
      <MemberPhoto member={member} size={10} >
        <div className="font-medium ml-2">{member.name}</div>
        <div className="font-light text-xs ml-2">{member.position}</div>
      </MemberPhoto>
    )
  }))

  return (
    <div className={props.className || ""}>
      <div className="mb-0 -mr-6">
        {selected.map((memberId, index) => {
          const member = members.filter(m => m.id === memberId)[0];
          return (
            <MemberPhoto member={member} size={10} key={memberId} className="inline-grid mr-6 mb-3">
              <div className="font-medium">{member.name}</div>
              <div
                className="text-xs cursor-pointer text-t-600"
                onClick={() => setSelected(selected.filter((m, i) => i !== index))}
              >Remove</div>
            </MemberPhoto>
          )
        })}
      </div>
      <LabtabSelect
        options={memberOptions}
        value={null}
        onChange={({value}) => setSelected([...selected, value])}
        className="mb-4"
        placeholder="Add lab members..."
        filterOption={(option, string) => option.label.props.member.name.toLowerCase().includes(string.toLowerCase())}
      />
    </div>
  );
};

MemberSelector.propTypes = {
  members: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default MemberSelector;