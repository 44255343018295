import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { MEMBER, POSSIBLE_PROJECTS } from "../queries";
import PageNotFound from "./PageNotFound";
import MemberForm from "../components/MemberForm";
import { detect404 } from "../forms";

const EditMemberPage = () => {

  const memberId = useRouteMatch("/members/:id/").params.id;

  const { loading, data, error } = useQuery(MEMBER, {variables: {id: memberId}});
  const { data: projectsData } = useQuery(POSSIBLE_PROJECTS);

  useDocumentTitle(data ? `${data.user.member.name} - LabTab` : "LabTab");

  if (loading || !projectsData) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const member = data.user.member;

  return (
    <Base center={true}>
      <MemberForm member={member} allProjects={projectsData.user.projects} />
    </Base>
  );
};

EditMemberPage.propTypes = {
  
};

export default EditMemberPage;