import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { separateMeetings } from "../utils";

const MetSummary = props => {

  const { member } = props;

  const [pastMeetings, futureMeetings] = separateMeetings(member.meetings);
  
  const now = new Date().getTime() / 1000;
  const timeSinceLastMeeting = pastMeetings.length ? now - pastMeetings[0].timestamp : null;
  const day = 24 * 3600 * 1000;
  const pastColor = timeSinceLastMeeting > (30 * day) || timeSinceLastMeeting === null ? "text-error-200" : timeSinceLastMeeting > (7 * day) ? "text-p-500" : "text-success-300";

  return (
    <div className={props.className || ""}>
      <div className="font-light">Last met: <span className={pastColor}>{pastMeetings.length ? (
          moment(pastMeetings[0].timestamp * 1000).fromNow()
        ) : "Never"}</span></div>
        {futureMeetings.length > 0 && <div className="font-light">Next meeting <span className="text-t-700 font-normal">{
          moment(futureMeetings[futureMeetings.length - 1].timestamp * 1000).fromNow()
        }</span></div>}
    </div>
  );
};

MetSummary.propTypes = {
  member: PropTypes.object.isRequired
};

export default MetSummary;