import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { TOKEN } from "../queries";
import { SIGNUP } from "../mutations";
import { UserContext } from "../contexts";
import { createErrorObject } from "../forms";
import logo from "../images/logo-text-color.svg"
import Button from "./Button";

const SignupForm = props => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [,setUser] = useContext(UserContext);
  const history = useHistory();
  const client = useApolloClient();

  const [signup, signupMutation] = useMutation(SIGNUP, {
    onCompleted: data => {
      setUser(data.signup.user);
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.signup.accessToken}
      });
      history.push("/");
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors))
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    signup({variables: {password, name, email}});
  }

  return (
    <form onSubmit={formSubmit} className={`bg-white rounded-md max-w-md mx-4 my-4 p-6 filter w-full shadow-md ${props.className || ""}`}>
      <Link to="/" className="block w-max mx-auto mb-6"><img src={logo} alt="labtab" /></Link>
      {errors.general && <div className="error mt-0">There was an error.</div>}
      <div className="mb-4">
        <label htmlFor="name">name</label>
        <div>
          <input
            type="text"
            id="name"
            value={name}
            onChange={e => setName(e.target.value)}
            autoComplete="name"
            className={`${errors.name ? "border-2 border-error-200" : ""}`}
            required
          />
          {errors.name && <div className="error">{errors.name}</div>}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="email">email</label>
        <div>
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
            className={`${errors.email ? "border-2 border-error-200" : ""}`}
            required
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="password">password</label>
        <div>
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            autoComplete="new-password"
            className={`${errors.password ? "border-2 border-error-200" : ""}`}
            required
          />
          {errors.password && <div className="error">{errors.password}</div>}
        </div>
      </div>

      <Button className="btn-primary py-2 mr-3" type="submit" loading={signupMutation.loading}>Sign Up</Button>
      <Link className="btn-tertiary py-2" to="/login/">Log In</Link>
    </form>
  );
};

SignupForm.propTypes = {
  
};

export default SignupForm;