import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";
import MemberPhoto from "./MemberPhoto";

const SearchResult = props => {

  const { member, project, meeting, term } = props;

  const splitString = (s, term, truncate) => {
    const loc = s.toLowerCase().search(term.toLowerCase());
    if (loc === -1) return [s, "", ""];
    let pre = s.substring(0, loc);
    const within = s.substring(loc, loc + term.length);
    let post = s.substring(loc + term.length);
    if (truncate && pre.length > 30)
      pre = "..." + pre.slice(pre.length - 27).trimStart();
    if (truncate && post.length > 30)
      post = post.slice(0, 27).trimEnd() + "...";
    return [pre, within, post]
  }

  if (member) {

    const [pre, within, post] = splitString(member.name, term);

    return (
      <Link className="block w-full rounded p-2 hover:bg-gray-200" to={`/members/${member.id}/`}>
        <MemberPhoto member={member} size={10} className="font-medium">
          {pre}
          <span className="text-p-500">{within}</span>
          {post}
        </MemberPhoto>
      </Link>
    )
  }

  if (project) {
    const [namePre, nameWithin, namePost] = splitString(project.name, term);
    const [descriptionPre, descriptionWithin, descriptionPost] = splitString(project.description, term, true);

    return (
      <Link className="grid grid-cols-max gap-2 items-center w-full rounded p-2 hover:bg-gray-200" to={`/projects/${project.id}/`}>
        <div style={{ backgroundColor: project.color }} className="rounded-full w-8 h-8" />
        <div>
          <div className="font-medium">{namePre}<span className="text-p-500">{nameWithin}</span>{namePost}</div>
          {descriptionWithin.length > 0 && <div className="text-xs">{descriptionPre}<span className="text-p-500">{descriptionWithin}</span>{descriptionPost}</div>}
        </div>
      </Link>
    )
  }
  
  if (meeting) {
    const [titlePre, titleWithin, titlePost] = splitString(meeting.title, term);
    const [textPre, textWithin, textPost] = splitString(meeting.text, term, true);

    return (
      <Link className="block w-full rounded p-2 hover:bg-gray-200" to={`/meetings/${meeting.id}/`}>
        <div className="text-t-500 text-xs">{moment(meeting.timestamp * 1000).tz(moment.tz.guess()).format("D MMMM YYYY, HH:mm")}</div>
        <div className="font-medium">{titlePre}<span className="text-p-500">{titleWithin}</span>{titlePost}</div>
        {textWithin.length > 0 && <div className="text-xs">{textPre}<span className="text-p-500">{textWithin}</span>{textPost}</div>}
      </Link>
    )
  }
};

SearchResult.propTypes = {
  member: PropTypes.object,
  project: PropTypes.object,
  meeting: PropTypes.object,
  term: PropTypes.string.isRequired,
};

export default SearchResult;