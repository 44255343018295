module.exports = {
  colors: {
    white: "#ffffff",
    p: {
      100: "#6AD9FB",
      200: "#4CAECC",
      300: "#38A5C7",
      400: "#0891B2",
      500: "#2C839E",
      600: "#3E7A8C",
      700: "#27748C",
      800: "#216378",
      900: "#1C5364",
    },
    s: {
      100: "#6AFBF1",
      200: "#4BCDC4",
      300: "#38C7BE",
      400: "#32B4AB",
      500: "#4EB1AB",
      600: "#2CA098",
      700: "#278C85",
      800: "#217872",
      900: "#1C645F",
    },
    t: {
      100: "#BF6AFB",
      200: "#974BCD",
      300: "#8B38C7",
      400: "#7E32B4",
      500: "#884EB1",
      600: "#702CA0",
      700: "#62278C",
      800: "#542178",
      900: "#461C64",
    },
    gray: {
      100: "#F9F9F9",
      200: "#E9E9E9",
      300: "#DFDFDF",
      400: "#CBCBCB",
      500: "#B2B2B2",
      600: "#999999",
      700: "#737373",
      800: "#595959",
      900: "#262626",
    },
    "gray-tint": {
      100: "#D3D8DA",
      200: "#BFD3D9",
      300: "#BFD9D7",
      400: "#506261",
      500: "#00000059",
    },
    success: {
      100: "#DBE7E3",
      200: "#01A96B",
      300: "#028857",
    },
    error: {
      100: "#E7DBDB",
      200: "#A61D1D",
      300: "#A90101",
      400: "#880202",
    }
  }
}