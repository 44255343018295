import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import PageNotFound from "./PageNotFound";
import { PROJECT } from "../queries";
import { detect404 } from "../forms";
import Meeting from "../components/Meeting";
import { separateMeetings } from "../utils";
import penIcon from "../images/edit.svg"
import MemberPhoto from "../components/MemberPhoto";

const ProjectPage = () => {

  const projectId = useRouteMatch("/projects/:id/").params.id;
  
  const { loading, data, error } = useQuery(PROJECT, {variables: {id: projectId}});
  
  useDocumentTitle(data ? `${data.user.project.name} - LabTab` : "LabTab");

  if (loading) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const project = data.user.project;

  const [pastMeetings,] = separateMeetings(project.meetings);

  return (
    <Base>
      <div
        className="bg-white rounded py-4 px-5 mx-auto max-w-4xl relative sm:px-6 sm:py-5 md:px-7 md:py-6"
        style={{borderTop: `14px solid ${project.color}`}}
      >
        <Link to={`/projects/${project.id}/edit/`} className="absolute top-4 right-4 w-5">
          <img className="opacity-90 hover:opacity-100" src={penIcon} alt="" />
        </Link>
        <h1 className="text-2xl mb-4 sm:text-3xl md:text-4xl lg:text-5l font-medium">
          {project.name}
        </h1>
        <div className="text-md font-light mb-4 sm:text-lg">{project.description}</div>
        
        <div className="flex flex-wrap -mr-3 -mb-3">
          {project.members.map(member => (
            <MemberPhoto key={member.id} member={member} size={10} className="mr-3 mb-3" isLink={true} />
          ))}
        </div>

        {pastMeetings.length > 0 && (
          <div className="mt-8">
            <h2 className="text-gray-800 text-xl mb-2">Meetings</h2>
            <div className="grid grid-cols-max gap-5 overflow-scroll no-scroll pb-1 mb-5 -mx-5 px-5 sm:-mx-6 sm:px-6 md:-mx-7 md:px-7">
              {pastMeetings.map(meeting => (
                <Meeting
                  meeting={meeting} key={meeting.id}
                  className="w-80 h-full inline-block bg-gray-100 hover:bg-gray-200"
                />
              ))}
              <div className="w-0 -ml-5 pl-5 sm:-ml-6 sm:pl-6 md:-ml-7 md:pl-7" />
            </div>
          </div>
        )}
      </div>
    </Base>
  );
};

ProjectPage.propTypes = {
  
};

export default ProjectPage;