import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { DELETE_MEMBER } from "../mutations";
import { MEETINGS, MEMBERS, PROJECTS } from "../queries";
import Modal from "./Modal";
import Button from "./Button";

const DeleteMemberModal = props => {

  const { showModal, setShowModal, member } = props;
  const history = useHistory();

  const [deleteMember, deleteMemberMutation] = useMutation(DELETE_MEMBER, {
    onCompleted: () => {
      history.push("/members/");
    },
    awaitRefetchQueries: true,
    refetchQueries: [{query: MEMBERS}, {query: MEETINGS}, {query: PROJECTS}]
  })

  if (!showModal) return <div />

  return (
    <Modal
      title="Delete Lab Member?"
      subtitle={member.name}
      text="Are you sure you want to delete this member? This can't be undone."
      setShowModal={setShowModal}
    >
      <Button className="btn-primary" onClick={() => deleteMember({variables: {id: member.id}})} loading={deleteMemberMutation.loading}>Delete</Button>
      <button className="btn-secondary ml-3" onClick={() => setShowModal(false)}>Keep</button>
    </Modal>
  );
};

DeleteMemberModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired
};

export default DeleteMemberModal;