import React, { useState } from "react";
import { useDocumentTitle } from "../hooks";
import UserInfoForm from "../components/UserInfoForm";
import UpdateEmailForm from "../components/UpdateEmailForm";
import UpdatePasswordForm from "../components/UpdatePasswordForm";
import DeleteAccountModal from "../components/DeleteAccountModal";
import Base from "./Base";

const SettingsPage = () => {

  const [showModal, setShowModal] = useState(false);

  useDocumentTitle("Settings - LabTab");
  
  return (
    <Base center={true}>
      <div className="bg-white rounded-md p-5 lg:w-min lg:p-8">
        <UserInfoForm />
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:w-max">
          <UpdateEmailForm />
          <UpdatePasswordForm />
        </div>
        <button className="btn-secondary mt-12 py-2 block ml-auto" onClick={() => setShowModal(true)}>Delete Account</button>
        <DeleteAccountModal showModal={showModal} setShowModal={setShowModal} />
      </div>
    </Base>
  );
};

SettingsPage.propTypes = {
  
};

export default SettingsPage;