
import { useState, useEffect } from "react";

export const usePageWidth = () => {
  const [width, setWidth] = useState();
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
}

export const useMainWidth = () => {
  const [width, setWidth] = useState();
  useEffect(() => {
    const handleResize = () => {
      const main = document.querySelector("main");
      const mainWidth = main.offsetWidth;
      const mainPadding = parseInt(window.getComputedStyle(main, null).getPropertyValue("padding")) * 2;
      setWidth(mainWidth - mainPadding);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return width;
}

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = title;
  }, [title]);
}