import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { DELETE_USER } from "../mutations";
import { useHistory } from "react-router";
import { UserContext } from "../contexts";
import Modal from "./Modal";
import Button from "./Button";

const DeleteAccountModal = props => {

  const { showModal, setShowModal } = props;
  const [,setUser] = useContext(UserContext);
  const history = useHistory();

  const [deleteUser, deleteUserMutation] = useMutation(DELETE_USER, {
    onCompleted: () => {
      setUser(false);
      history.push("/");
    }
  })

  if (!showModal) return <div />

  return (
    <Modal
      title="Delete Your Account?"
      text="Are you sure you want to delete your account? This can't be undone and your data will be deleted."
      setShowModal={setShowModal}
    >
      <div className="ml-auto w-max">
        <Button className="btn-primary" onClick={() => deleteUser()} loading={deleteUserMutation.loading}>Delete</Button>
        <button className="btn-secondary ml-3" onClick={() => setShowModal(false)}>Keep</button>
      </div>
    </Modal>
  );
};

DeleteAccountModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default DeleteAccountModal;