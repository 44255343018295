import gql from "graphql-tag";
import { USER_FIELDS } from "./queries";

export const LOGIN = gql`mutation login($email: String! $password: String!) {
  login(email: $email password: $password) { accessToken user {
    ...UserFields
  } }
} ${USER_FIELDS}`;

export const LOGOUT = gql`mutation { logout { success } }`;

export const SIGNUP = gql`mutation login(
  $password: String! $name: String! $email: String!
) { signup(
  password: $password name: $name email: $email
) { accessToken user { 
  ...UserFields
 } }
} ${USER_FIELDS}`;

export const UPDATE_USER = gql`mutation updateUser(
  $email: String! $name: String!
) {
  updateUser(email: $email name: $name) {
    user { ...UserFields }
  }
} ${USER_FIELDS}`;

export const UPDATE_PASSWORD = gql`mutation updatePassword($current: String! $new: String!) {
  updatePassword(current: $current new: $new) { success }
}`;

export const DELETE_USER = gql`mutation {
  deleteUser { success }
}`;

export const JOIN_LIST = gql`mutation joinWaitingList($email: String! $canTest: Boolean!) {
  addToWaitingList(email: $email canTest: $canTest) { success }
}`;

export const CREATE_MEMBER = gql`mutation(
  $name: String!, $position: String!, $image: Upload $projects: [ID]
) {
  createMember(name: $name, position: $position, image: $image projects: $projects) { member { id } }
}`;

export const UPDATE_MEMBER = gql`mutation(
  $id: ID!, $name: String!, $position: String!, $image: Upload $projects: [ID]
) {
  updateMember(id: $id, name: $name, position: $position, image: $image projects: $projects) { member { id } }
}`;

export const DELETE_MEMBER = gql`mutation($id: ID!) { deleteMember(id: $id) { success }}`;

export const CREATE_MEETING = gql`mutation(
  $timestamp: Float!, $timezone: String!, $title: String!, $text: String
  $members: [ID],  $projects: [ID]
) {
  createMeeting(
    members: $members projects: $projects
    timestamp: $timestamp, timezone: $timezone, title: $title, text: $text
  ) {
    meeting { id }
  }
}`;

export const UPDATE_MEETING = gql`mutation(
  $id: ID!, $timestamp: Float!, $timezone: String!, $title: String!, $text: String
  $members: [ID] $projects: [ID]
) {
  updateMeeting(
    id: $id, timestamp: $timestamp, timezone: $timezone, title: $title, text: $text
    members: $members projects: $projects
  ) {
    meeting { id }
  }
}`;

export const DELETE_MEETING = gql`mutation($id: ID!) { deleteMeeting(id: $id) { success }}`;

export const CREATE_PROJECT = gql`mutation(
  $members: [ID], $name: String! $description: String! $color: String!
) {
  createProject(
    members: $members, name: $name, description: $description, color: $color
  ) { project { id } }
}`;

export const UPDATE_PROJECT = gql`mutation(
  $id: ID!, $members: [ID], $name: String! $description: String! $color: String!
) {
  updateProject(
    id: $id, members: $members, name: $name, description: $description, color: $color
  ) { project { id } }
}`;

export const DELETE_PROJECT = gql`mutation($id: ID!) { deleteProject(id: $id) { success }}`;
