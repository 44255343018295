import React from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { PROJECT, POSSIBLE_MEMBERS } from "../queries";
import PageNotFound from "./PageNotFound";
import ProjectForm from "../components/ProjectForm";
import { detect404 } from "../forms";

const EditProjectPage = () => {

  const projectId = useRouteMatch("/projects/:id/").params.id;

  const { loading, data, error } = useQuery(PROJECT, {variables: {id: projectId}});
  const { data: membersData } = useQuery(POSSIBLE_MEMBERS);

  useDocumentTitle(data ? `${data.user.project.title} - LabTab` : "LabTab");

  if (loading || !membersData) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const project = data.user.project;

  return (
    <Base center={true}>
      <ProjectForm project={project} allMembers={membersData.user.members} />
    </Base>
  );
};

EditProjectPage.propTypes = {
  
};

export default EditProjectPage;