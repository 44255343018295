import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import ReactTooltip from "react-tooltip";
import { UserContext } from "../contexts";
import { TOKEN } from "../queries";
import { LOGOUT } from "../mutations";
import memberIcon from "../images/member-icon.svg";
import meetingIcon from "../images/meeting-icon.svg";
import projectIcon from "../images/project-icon.svg";
import paperIcon from "../images/paper-icon.svg";
import grantIcon from "../images/grant-icon.svg";
import logo from "../images/logo-text.svg"

const Sidebar = props  => {

  const { menuDown } = props;
  const [,setUser] = useContext(UserContext);
  const history = useHistory();
  const client = useApolloClient();

  const [logout,] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(false);
      client.cache.writeQuery({query: TOKEN, data: {accessToken: null}});
      history.push("/");
    }
  });

  return (
    <div className={`${menuDown ? "top-12" : "-top-52"} z-40 duration-200 transition-top bg-p-200 w-full text-white text-sm font-medium absolute sm:static sm:areas-sidebar sm:bg-p-500`}>
      <div className="hidden h-14 items-center justify-start sm:flex">
        <Link to="/" className="h-3/5 ml-6 flex justify-start">
          <img src={logo} className="h-full" alt="labtab" />
        </Link>
      </div>
      
      <div className="grid px-6 py-3 leading-10 text-100 sm:mt-12 sm:gap-2 sm:border-b sm:border-p-700 sm:px-0">
        <Link to="/members/" className="flex border-b border-p-300 items-center sm:border-0 sm:hover:bg-p-600 sm:px-6">
          <img src={memberIcon} alt="" className="w-5 mr-3" />
          Lab Members
        </Link>
        
        <Link to="/meetings/" className="flex border-b border-p-300 items-center sm:border-0 sm:hover:bg-p-600 sm:px-6">
          <img src={meetingIcon} alt="" className="w-5 mr-3" />
          Meetings
        </Link>

        <Link to="/projects/" className="flex border-b border-p-300 items-center sm:border-0 sm:hover:bg-p-600 sm:px-6">
          <img src={projectIcon} alt="" className="w-5 mr-3" />
          Projects
        </Link>


        <div data-tip data-for="soon" className="opacity-30 cursor-default flex border-b border-p-300 items-center sm:border-0 sm:px-6">
          <img src={paperIcon} alt="" className="w-5 mr-3" />
          Papers
        </div>

        <div data-tip data-for="soon" className="opacity-30 cursor-default flex border-b border-p-300 items-center sm:border-0 sm:px-6">
          <img src={grantIcon} alt="" className="w-5 mr-3" />
          Grants
        </div>

        <ReactTooltip id="soon">Coming Soon</ReactTooltip>
      </div>

      <div className="flex justify-end pr-6 pb-3 sm:hidden">
        <Link to="/settings/">Settings</Link>
        <div onClick={logout} className="ml-3 cursor-pointer">Log Out</div>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  menuDown: PropTypes.bool
};

export default Sidebar;