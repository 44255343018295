import gql from "graphql-tag";

export const USER_FIELDS = gql`
  fragment UserFields on UserType { 
    id email name
  }`;

export const USER = gql`{
  user { 
    ...UserFields
  }
} ${USER_FIELDS}`;

export const TOKEN = gql`{ accessToken }`;

export const HOME = gql`{ user {
  meetings { id timestamp title members { id name image } }
} }`

export const MEMBERS = gql`{
  user { 
    ...UserFields
    members { id name position image meetings { timestamp } }
  }
} ${USER_FIELDS}`;

export const POSSIBLE_MEMBERS = gql`{
  user { 
    ...UserFields
    members { id name position image }
  }
} ${USER_FIELDS}`;

export const MEMBER = gql`query member($id: ID!) {
  user { 
    ...UserFields
    member(id: $id) {
      id name position image lastMeetingTimestamp
      meetings { id title text timestamp timezone }
      projects { id name color description }
    }
  }
} ${USER_FIELDS}`;

export const MEETINGS = gql`{
  user { 
    ...UserFields
    meetings { id title text timestamp timezone members {
      id name position image
    } }
  }
} ${USER_FIELDS}`;

export const MEETING = gql`query meeting($id: ID!) {
  user { 
    ...UserFields
    meeting(id: $id) {
      id title text timestamp timezone
      members { id name position image }
      projects { id name color description }
    }
  }
} ${USER_FIELDS}`;

export const PROJECTS = gql`{
  user { 
    ...UserFields
    projects { id name color description members {
      id name position image
    } }
  }
} ${USER_FIELDS}`;

export const POSSIBLE_PROJECTS = gql`{
  user { 
    ...UserFields
    projects { id name color }
  }
} ${USER_FIELDS}`;

export const PROJECT = gql`query project($id: ID!) {
  user { 
    ...UserFields
    project(id: $id) { 
      id name description color
      members { id name position image }
      meetings { id title text timestamp timezone }
    }
  }
} ${USER_FIELDS}`;

export const SEARCH = gql`query search($term: String!) { search(term: $term) {
  members { id name image }
  projects { id name color description }
  meetings { id title text timestamp }
} }`;