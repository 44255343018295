import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import moment from "moment-timezone";
import { CREATE_MEETING, UPDATE_MEETING } from "../mutations";
import { createErrorObject } from "../forms";
import { MEETING, MEETINGS } from "../queries";
import DeleteMeetingModal from "./DeleteMeetingModal";
import DatetimePicker from "./DatetimePicker";
import Button from "./Button";
import MemberSelector from "./MemberSelector";
import ProjectSelector from "./ProjectSelector";

const MeetingForm = props => {

  const { meeting, allMembers, allProjects, memberId } = props;

  const [title, setTitle] = useState(meeting ? meeting.title : "");
  const [text, setText] = useState(meeting ? meeting.text : "");
  const [timestamp, setTimestamp] = useState(meeting ? meeting.timestamp : moment().unix());
  const [timezone,] = useState(meeting ? meeting.timezone : moment.tz.guess());
  const [members, setMembers] = useState(meeting ? meeting.members.map(m => m.id) : memberId ? [memberId] : []);
  const [projects, setProjects] = useState(meeting ? meeting.projects.map(p => p.id) : []);
  const [errors, setErrors] = useState({});
  const [showDeletionModal, setShowDeletionModal] = useState(false);
  const history = useHistory();

  const [createMeeting, createMeetingMutation] = useMutation(CREATE_MEETING, {
    onCompleted: ({createMeeting: {meeting: {id}}}) => {
      history.push(`/meetings/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: MEETINGS}],
    awaitRefetchQueries: true
  })

  const [updateMeeting, updateMeetingMutation] = useMutation(UPDATE_MEETING, {
    onCompleted: ({updateMeeting: {meeting: {id}}}) => {
      history.push(`/meetings/${id}/`);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {query: MEETING, variables: {id: meeting && meeting.id}}, {query: MEETINGS}
    ]
  })

  const formSubmit = e => {
    e.preventDefault();
    if (meeting) {
      updateMeeting({
        variables: {id: meeting.id, title, text, timestamp, timezone, members, projects}
      })
    } else {
      createMeeting({
        variables: {title, text, timestamp, timezone, members, projects}
      })
    }
  }

  return (
    <>
      <form onSubmit={formSubmit} className={`bg-white rounded-md p-5 w-full max-w-4xl md:p-6 ${props.className || ""}`}>
        {errors.general && <div className="error">There was an error.</div>}

        {!meeting && <h1 className="text-3xl font-medium text-center mb-6">Create a meeting</h1>}

        <div className="my-6">
          <DatetimePicker
            timestamp={timestamp} setTimestamp={setTimestamp} 
            required={true} id="timestamp" center={true}
            className="max-w-xs mx-auto"
          />
          {errors.timestamp && <div className="error">{errors.timestamp}</div>}
          {!meeting && <div className="max-w-xs text-xs mx-auto text-center text-gray-600 mt-1">
            Schedule a meeting by selecting a date in the future, or leave it as
            is to create a meeting now
          </div>}
        </div>

        <MemberSelector members={allMembers} selected={members} setSelected={setMembers} />

        <ProjectSelector
          projects={allProjects}
          selected={projects}
          setSelected={setProjects}
          className="mb-3"
        />

        <div className="mb-3">
          <input
            id="title"
            value={title}
            onChange={e => setTitle(e.target.value)}
            className={`text-xl font-medium text-p-600  ${errors.title ? "border-2 border-error-200" : ""}`}
            placeholder="Title"
            required
          />
          {errors.title && <div className="error">{errors.title}</div>}
        </div>

        <div className="mb-6">
          <textarea
            id="text"
            value={text}
            onChange={e => setText(e.target.value)}
            className={`h-96 resize-none ${errors.text ? "border-2 border-error-200" : ""}`}
            placeholder="Meeting text"
            required
          />
          {errors.text && <div className="error">{errors.text}</div>}
        </div>

        <div className={`${meeting ? "flex justify-between" : ""}`}>
          <Button type="submit" className={`btn-primary ${meeting ? "" : "ml-auto"} block md:ml-0`} loading={createMeetingMutation.loading || updateMeetingMutation.loading}>{meeting ? "Save" : "Create"}</Button>
          {meeting && (
            <button type="button" className="btn-secondary" onClick={() => setShowDeletionModal(true)}>Delete Meeting</button>
          )}
        </div>
      </form>
      {showDeletionModal && (
        <DeleteMeetingModal showModal={showDeletionModal} setShowModal={setShowDeletionModal} meeting={meeting} />
      )}
    </>
  );
};

MeetingForm.propTypes = {
  meeting: PropTypes.object,
  member: PropTypes.object,
  allMembers: PropTypes.array.isRequired,
};

export default MeetingForm;