import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import MemberPhoto from "./MemberPhoto";

const Meeting = props => {

  const { meeting, showMember, hideText } = props;

  return (
    <Link to={`/meetings/${meeting.id}/`} className={`flex flex-col justify-between bg-white rounded px-4 py-3 filter shadow hover:bg-gray-100 ${props.className || ""}`} style={props.style}>
      <div>
        <div className="flex justify-between text-xs">
          <div className="text-gray-400">{moment(meeting.timestamp * 1000).tz(moment.tz.guess()).format("D MMMM YYYY, HH:mm")}</div>
          <div className="text-t-500">{moment(meeting.timestamp * 1000).fromNow()}</div>
        </div>
        <div className="text-xl text-gray-800">{meeting.title}</div>
        <div className={`font-light text-xs text-gray-800 ${showMember ? "mb-4" : ""}`}>
          {!hideText && 
          `${meeting.text.slice(0, 200)}${meeting.text.length > 200 ? "..." : ""}`
          }
        </div>
      </div>
      {showMember && (
        <div className="-mr-3 -mb-2">
          {meeting.members.map(member => (
            <MemberPhoto member={member} size={5} key={member.id} className="inline-grid mr-3 mb-2">
              <div className="text-xs text-gray-800">{member.name}</div>
            </MemberPhoto>
          ))}
        </div>
      )}
    </Link>
  );
};

Meeting.propTypes = {
  meeting: PropTypes.object.isRequired,
  showMember: PropTypes.bool,
};

export default Meeting;