  
import React from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";

const BinaryToggle = props => {

  const { trueText, falseText, value, setValue } = props;

  return (
    <div className={`flex ${props.className || ""}`}>
      <label className="w-24 text-right mr-2 cursor-pointer mb-0" onClick={() => setValue(false)}>{falseText}</label>
      <Toggle
        checked={value}
        onChange={() => setValue(!value)}
        icons={false}
      />
      <label className="ml-2 cursor-pointer mb-0" onClick={() => setValue(true)}>{trueText}</label>
    </div>
  );
};

BinaryToggle.propTypes = {
  trueText: PropTypes.string.isRequired,
  falseText: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default BinaryToggle;