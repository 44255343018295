import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { MEMBERS } from "../queries";
import Member from "../components/Member";

const MembersPage = () => {

  useDocumentTitle("Lab Members - LabTab");

  const { loading, data } = useQuery(MEMBERS);

  if (loading) return <Base loading={true} />

  const members = data.user.members;

  if (!members.length) {
    return (
      <Base>
        <div className="no-data">
          You haven't added any lab members yet - <br />would you like
          to <Link to="/members/new/"> add one</Link>?
        </div>
      </Base>
    )
  }

  return (
    <Base>
      <Link className="btn-primary block w-max mb-4 py-2" to="/members/new/">New Member</Link>
      <div className="grid gap-4 sm:grid-cols-96">
        {members.map(member => <Member member={member} key={member.id} />)}
      </div>
    </Base>
  );
};

MembersPage.propTypes = {
  
};

export default MembersPage;