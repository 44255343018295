import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import successIcon from "../images/success.svg";

const CornerMessage = props => {

  const { children, ttl } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 50);
    setTimeout(() => setShow(false), ttl || 3000);
  }, [ttl])

  return (
    <div className={`${show ? "" : "opacity-0"} duration-200 fixed right-2 top-16 z-50 flex bg-success-100 text-success-200 py-3 px-4 rounded filter shadow ${props.className || ""}`}>
      <img src={successIcon} alt="" className="w-4 mr-2" />
      <div className="text-sm">{children}</div>
    </div>
  );
};

CornerMessage.propTypes = {
  ttl: PropTypes.number
};

export default CornerMessage;