export const separateMeetings = meetings => {
  /**
   * Takes a list of meetings, and divides them into those in the past, and
   * those in the future.
   */

   const now = new Date().getTime() / 1000;
   const pastMeetings = meetings.filter(m => m.timestamp < now);
   const futureMeetings = meetings.filter(m => m.timestamp >= now);
   return [pastMeetings, futureMeetings];
}