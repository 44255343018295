import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { UserContext } from "../contexts";
import { UPDATE_USER } from "../mutations";
import { createErrorObject } from "../forms";

const UserInfoForm = props => {

  const [user, setUser] = useContext(UserContext);
  const [editedName, setEditedName] = useState(null);
  const [errors, setErrors] = useState({});

  const [updateUser, updateUserMutation] = useMutation(UPDATE_USER, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: data => {
      setUser(data.updateUser.user);
      setErrors({});
      setEditedName(null);
    }
  });

  const saveName = () => {
    updateUser({variables: {name: editedName, email: user.email}});
  }

  const nameEnter = e => {
    if (e.keyCode === 13) saveName();
  }

  return (
    <div className={`mb-10 pb-8 border-b border-gray-300 ${props.className || ""}`}>
      <input
        value={editedName || user.name}
        onChange={e => setEditedName(e.target.value)}
        onBlur={saveName}
        onKeyUp={nameEnter}
        disabled={updateUserMutation.loading}
        className="mb-1 bg-white text-4xl p-0 text-gray-900 font-normal"
      />
      <div className="text-xl font-light text-gray-800">Settings</div>
    </div>
  );
};

UserInfoForm.propTypes = {
  
};

export default UserInfoForm;