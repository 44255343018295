import React from "react";
import PropTypes from "prop-types";
import LabtabSelect from "./LabtabSelect";
const colors = require("../colors").colors;

const ProjectSelector = props => {

  const { projects, selected, setSelected } = props;

  const projectOptions = projects.map(project => ({
    value: project.id, label: (
      <div className="grid grid-cols-max items-center" projectname={project.name}>
        <div className="w-4 h-4 rounded-full mr-2" style={{backgroundColor: project.color}} />
        <div>{project.name}</div>
      </div>
    )
  }))

  const customStyles = {
    multiValueRemove: provided => ({
      ...provided,
      cursor: "pointer",
      '&:hover': {
        backgroundColor: colors.gray[300],
        color: colors.gray[900]
      }
    }),
    multiValue: provided => ({
      ...provided,
      marginRight: 4,
      display: "flex"
    }),
  }

  return (
    <div className={props.className || ""}>
      <LabtabSelect
        options={projectOptions}
        isMulti={true}
        placeholder="Select projects..."
        value={projectOptions.filter(p => selected.includes(p.value))}
        onChange={values => setSelected(values.map(val => val.value))}
        styles={customStyles}
        filterOption={(option, string) => option.label.props.projectname.toLowerCase().includes(string.toLowerCase())}
      />
    </div>
  );
};

ProjectSelector.propTypes = {
  projects: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired
};

export default ProjectSelector;