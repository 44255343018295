import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_PASSWORD } from "../mutations";
import { createErrorObject } from "../forms";
import CornerMessage from "./CornerMessage";
import Button from "./Button";

const UpdatePasswordForm = props => {

  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const [updatePassword, updatePasswordrMutation] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      setNewPassword("");
      setCurrentPassword("");
      setErrors({});
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors))
    }
  });

  const onSubmit = e => {
    e.preventDefault();
    updatePassword({variables: {current: currentPassword, new: newPassword}});
  }

  return (
    <form onSubmit={onSubmit} className={`mb-8 lg:w-80 ${props.className || ""}`}>
      <div>
        <label htmlFor="newPassword">New Password</label>
        <div className="mb-4">
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            autoComplete="new-password"
            className={`${errors.new ? "border-2 border-error-200" : ""}`}
            required
          />
          {errors.new && <div className="error">{errors.new}</div>}
        </div>
      </div>
      <div>
        <label htmlFor="currentPassword">Current Password</label>
        <div className="mb-4">
          <input
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            autoComplete="current-password"
            className={`${errors.current ? "border-2 border-error-200" : ""}`}
            required
          />
          {errors.current && <div className="error">{errors.current}</div>}
        </div>
      </div>
      <Button className="btn-primary w-full py-2" type="submit" loading={updatePasswordrMutation.loading}>Update Password</Button>
      {showSuccess && <CornerMessage ttl={4000}>Password successfully updated</CornerMessage>}
    </form>
  );
};

UpdatePasswordForm.propTypes = {
  
};

export default UpdatePasswordForm;