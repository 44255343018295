import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import moment from "moment-timezone";
import ReactMarkdown from "react-markdown";
import Base from "./Base";
import PageNotFound from "./PageNotFound";
import { MEETING } from "../queries";
import MemberPhoto from "../components/MemberPhoto";
import penIcon from "../images/edit.svg";
import { detect404 } from "../forms";
import ProjectLink from "../components/ProjectLink";

const MeetingPage = () => {

  
  const meetingId = useRouteMatch("/meetings/:id/").params.id;
  
  const { loading, data, error } = useQuery(MEETING, {variables: {id: meetingId}});
  
  useDocumentTitle(data ? `${data.user.meeting.title} - LabTab` : "LabTab");

  if (loading) return <Base loading={true} />

  if (detect404(error)) return <PageNotFound />

  const meeting = data.user.meeting;

  return (
    <Base>
      <div className="bg-white rounded py-4 px-5 mx-auto max-w-4xl relative sm:px-6 sm:py-5">
        {meeting.members.length > 0 && <div className="mb-4 border-b pb-3 border-gray-200">
          {meeting.members.map(member => (
            <MemberPhoto member={member} size={10} sm={12} md={14} isLink={true} key={member.id} className="inline-grid mr-8 mb-3">
              <div className="text-lg font-medium">{member.name}</div>
              <div className="text-xs font-light text-gray-700">{member.position}</div>
            </MemberPhoto>
          ))}
        </div>}

        {meeting.projects.length > 0 && <div className="border-b pb-4 border-gray-200 -mr-3">
          {meeting.projects.map(project => (
            <ProjectLink project={project} key={project.id} className="-ml-2 mr-4" />
          ))}
        </div>}

        <div className={`text-p-500 text-lg mb-1 ${meeting.projects.length === 0 && meeting.members.length === 0 ? "" : "mt-6"}`}>{moment(meeting.timestamp * 1000).tz(moment.tz.guess()).format("DD MMMM YYYY, HH:mm")}</div>

        <h1 className="font-medium text-2xl mb-3 sm:text-3xl">{meeting.title}</h1>

        <ReactMarkdown className="text-sm font-light sm:text-base">{meeting.text}</ReactMarkdown>
        <Link to={`/meetings/${meeting.id}/edit/`} className="absolute top-4 right-4 w-5">
          <img  className="opacity-90 hover:opacity-100" src={penIcon} alt="" />
        </Link>
        
      </div>
    </Base>
  );
};

MeetingPage.propTypes = {
  
};

export default MeetingPage;