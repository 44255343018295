import React from "react";
import Div100vh from "react-div-100vh";
import { Link } from "react-router-dom";
import logo from "../images/logo-text.svg"
import { useDocumentTitle } from "../hooks";

const PageNotFound = () => {

  useDocumentTitle("Page Not Found - LabTab");

  return (
    <Div100vh className="bg-p-700 flex flex-col justify-center">
      <Link to="/" className="block mx-auto w-max mb-10"><img src={logo} alt="LabTab" /></Link>
      <h1 className="text-4xl font-extralight text-p-100 mx-auto sm:text-5xl md:text-6xl">Page Not Found</h1>
    </Div100vh>
  );
};

PageNotFound.propTypes = {
  
};

export default PageNotFound;