import React, { useState } from "react";
import logo from "../../images/logo-dark.svg";
import logoword from "../../images/logo-text-dark.svg";
import frame from "../../images/frame.png"
import imperial from "../../images/imperial.svg";
import ucl from "../../images/ucl.jpeg";
import edinburgh from "../../images/edinburgh.png";
import crick from "../../images/crick.jpeg";
import swc from "../../images/swc.svg";
import { Link } from "react-router-dom";

const Hero = () => {

  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className="bg-gray-50">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="e229dbec-10e9-49ee-8ec3-0286ca089edf" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
            </svg>
            <svg className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
              <defs>
                <pattern id="d2a68204-c383-44b1-b99f-42ccff4e5365" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="784" fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
            </svg>
          </div>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <div className="max-w-7xl mx-auto px-6 sm:px-6">
            <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
              <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <div>
                    <span className="sr-only">LabTab</span>
                    <img className="h-10 w-auto sm:h-12" src={logoword} alt="" />
                  </div>
                  <div className="-mr-2 flex items-center md:hidden">
                    <button onClick={() => setShowMenu(true)} type="button" className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-s-500" aria-expanded="false">
                      <span className="sr-only">Open main menu</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="hidden md:flex md:space-x-10">
                <a href="/#features" className="font-medium text-gray-700 hover:text-p-700">Features</a>
                <Link to="/signup/" className="font-medium text-gray-700 hover:text-p-700">Sign up</Link>
              </div>
              <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
                <span className="inline-flex rounded-md shadow">
                  <Link to="/login/" className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-p-400 hover:bg-p-500">
                    Log in
                  </Link>
                </span>
              </div>
            </nav>
          </div>

          {showMenu && <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={logo} alt=""/>
                </div>
                <div className="-mr-2">
                  <button onClick={() => setShowMenu(false)} type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-s-500">
                    <span className="sr-only">Close main menu</span>
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                <a href="#features" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Features</a>

                <Link to="/signup/" className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50">Sign Up</Link>

              </div>
              <Link to="/login/" className="block w-full px-5 py-3 text-center font-bold text-p-600 bg-gray-50 hover:bg-gray-100 hover:text-s-700">
                Log in
              </Link>
            </div>
          </div>}

          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-semibold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block leading-snug">A lab management app</span>
                <span className="block text-p-400">specifically for PIs</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-700 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                One unified platform for running your lab, managing your meetings and deadlines, and organising your projects - to give you your time back.
              </p>
              <a
                href="#signup"
                className="inline-flex mt-8 items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-p-400 hover:bg-p-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-p-800"
              >
                Join the waitlist
              </a> 
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1"></div>
            <div className="flex-1 w-full bg-white"></div>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <img className="relative rounded-lg shadow-lg" src={frame} alt="App screenshot" />
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <h2 className="text-center text-gray-400 text-sm font-semibold uppercase tracking-wide">Trusted by labs at leading universities and institutes</h2>
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 gap-8 md:grid-cols-6 items-center lg:grid-cols-5">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-12" src={imperial} alt="Imperial"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-24" src={crick} alt="Crick"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
              <img className="h-10" src={edinburgh} alt="Edinburgh"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img className="h-12" src={ucl} alt="UCL"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
              <img className="h-12" src={swc} alt="SWC"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  
};

export default Hero;