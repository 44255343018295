import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SEARCH } from "../queries";
import SearchResult from "./SearchResult";
import BarLoader from "react-spinners/BarLoader";
const colors = require("../colors").colors;

const SearchBar = () => {

  const [term, setTerm] = useState("");

  const [search, { data, loading }] = useLazyQuery(SEARCH);

  useEffect(() => {
    const dismiss = () => setTerm("");
    window.addEventListener("click", dismiss);
    return () => window.removeEventListener("click", dismiss);
  })

  const members = data ? data.search.members : [];
  const projects = data ? data.search.projects : [];
  const meetings = data ? data.search.meetings : [];
  const resultsCount = members.length + projects.length + meetings.length;

  const showResults = term.length >= 3;

  const keyUp = e => {
    const query = e.target.value;
    if (query.length >= 2) {
      search({variables: {term}});
    }
  }

  return (
    <div className="flex-grow max-w-md mr-4 ml-4 sm:ml-0 sm:mr-3 sm:relative">
      <input
        value={term}
        onChange={e => setTerm(e.target.value)}
        onKeyUp={keyUp}
        placeholder="Search LabTab"
        className="block w-full"
      />
      {showResults && (
        <div className="absolute bg-white text-gray-800 w-11/12 inset-x-1 mx-auto shadow-lg top-16 rounded-md max-h-120 overflow-scroll no-scroll sm:w-full sm:l-0 sm:r-0 sm:inset-x-0 sm:top-14">
          {Boolean(loading) && (
            <div className="h-14 w-full flex justify-center items-center">
              <BarLoader color={colors.p[500]} css="width: 50%; max-width: 300px" />
            </div>
          )}
          {Boolean(data) && resultsCount === 0 && (
            <div className="h-14 w-full px-3 flex items-center text-p-800">No results</div>
          )}
          {Boolean(data) && resultsCount > 0 && (
            <div className="py-3 px-3 grid gap-7" onClick={() => setTerm("")}>
              {members.length > 0 && (
                <div className="w-full">
                  <div className="text-p-600 text-sm font-medium mb-3">Lab Members</div>
                  {members.map(member => (
                    <SearchResult member={member} key={member.id} term={term} />
                  ))}
                </div>
              )}
              {projects.length > 0 && (
                <div>
                  <div className="text-p-600 text-sm font-medium mb-3">Projects</div>
                  {projects.map(project => (
                    <SearchResult project={project} key={project.id} term={term} />
                  ))}
                </div>
              )}
              {meetings.length > 0 && (
                <div>
                  <div className="text-p-600 text-sm font-medium mb-3">Lab Meetings</div>
                  {meetings.map(meeting => (
                    <SearchResult meeting={meeting} key={meeting.id} term={term} />
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  
};

export default SearchBar;