import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Modal from "./Modal";
import leftArrow from "../images/left.svg";
import rightArrow from "../images/right.svg";

const DatetimePicker = props => {

  const { timestamp, setTimestamp, center } = props;
  const [showPicker, setShowPicker] = useState(false);
  const [hourText, setHourText] = useState(null);
  const [minuteText, setMinuteText] = useState(null);
  const [editingText, setEditingText] = useState(false);
  
  const timezone = moment.tz.guess();
  const momentDatetime = moment(parseInt(timestamp) * 1000).tz(timezone);
  const datetimeString = momentDatetime.format("dddd D MMMM, YYYY, HH:mm");

  // Get the relevant month as array of week days
  const monthStart = moment(parseInt(timestamp) * 1000).tz(timezone).startOf("month");
  const dayOfWeek = monthStart.day();
  let day = 0;
  if (dayOfWeek === 0) {
    day -= 5;
  } else {
    day = -monthStart.day() + 2;
  }
  const weeks = [[]];
  while(day <= monthStart.daysInMonth()) {
    weeks[weeks.length - 1].push(day > 0 ? day : null);
    if (weeks[weeks.length - 1].length === 7) weeks.push([]);
    day += 1;
  }
  while (weeks[weeks.length - 1].length !== 7) weeks[weeks.length - 1].push(null);

  // Function for moving to another month
  const changeMonth = number => {
    momentDatetime.add(number, "month");
    setTimestamp(momentDatetime.unix());
  }

  // Function for moving to specific day
  const daySelected = e => {
    const day = e.target.innerText;
    if (day.length) {
      momentDatetime.set("date", parseInt(day));
      setTimestamp(momentDatetime.unix());
    }
  }

  const textValidTime = (text, max) => {
    const int = parseInt(text);
    return (!isNaN(int) && int >= 0 && int <= max)
  }

  const hourChanged = e => {
    const text = e.target.value;
    setHourText(text);
    if (textValidTime(text, 23)) {
      momentDatetime.set("hour", text);
      setTimestamp(momentDatetime.unix());
    }
  }

  const hourBlur = () => {
    if (!textValidTime(hourText, 23)) setHourText(null);
    setEditingText(false);
  }

  const minuteChanged = e => {
    const text = e.target.value;
    setMinuteText(text);
    if (textValidTime(text, 59)) {
      momentDatetime.set("minute", text);
      setTimestamp(momentDatetime.unix());
    }
  }

  const minuteBlur = () => {
    if (!textValidTime(minuteText, 59)) setMinuteText(null);
    setEditingText(false);
  }

  const keyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      setTimestamp(momentDatetime.valueOf() / 1000);
      setShowPicker(false);
    }
    if (e.keyCode === 37 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      if (e.ctrlKey && e.shiftKey) {
        momentDatetime.subtract(1, "year");
        setTimestamp(momentDatetime.valueOf() / 1000);
      } else if (e.shiftKey) {
        changeMonth(-1);
      } else {
        momentDatetime.subtract(1, "day");
        setTimestamp(momentDatetime.valueOf() / 1000);
      }
    }
    if (e.keyCode === 39 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      if (e.ctrlKey && e.shiftKey) {
        momentDatetime.add(1, "year");
        setTimestamp(momentDatetime.valueOf() / 1000);
      } else if (e.shiftKey) {
        changeMonth(1);
      } else {
        momentDatetime.add(1, "day");
        setTimestamp(momentDatetime.valueOf() / 1000);
      }
    }
    if (e.keyCode === 38 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      momentDatetime.subtract(7, "day");
      setTimestamp(momentDatetime.valueOf() / 1000);
    }
    if (e.keyCode === 40 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      momentDatetime.add(7, "day");
      setTimestamp(momentDatetime.valueOf() / 1000);
    }
  }

  return (
    <div onKeyDown={keyDown} tabIndex="-1" className={`focus:outline-none ${props.className || ""}`}>
      <input
        value={datetimeString} className={`cursor-pointer text-p-500 font-normal ${center ? "text-center" : ""}`}
        onClick={() => setShowPicker(true)}
        readOnly={true}
      />
      {showPicker && (
        <Modal setShowModal={setShowPicker} removePadding={true}>
          <div className="bg-p-500 text-white text-center py-3 text-xl">{momentDatetime.format("DD MMMM")}</div>
          <div className="text-center text-p-300 py-2 font-medium text-lg">{momentDatetime.format("YYYY")}</div>
          
          <div className="flex content-between items-center text-sm mb-4 px-3 mx-auto w-max font-medium">
            <img src={leftArrow} className="mx-1 cursor-pointer" alt="previous" onClick={() => changeMonth(-1)}/>
            <div className="flex justify-evenly">
              <div className="mx-2" onClick={() => changeMonth(-2)}>
                {moment(parseInt(timestamp) * 1000).tz(timezone).subtract(2, "month").format("MMM")}
              </div>
              <div className="mx-2" onClick={() => changeMonth(-1)}>
                {moment(parseInt(timestamp) * 1000).tz(timezone).subtract(1, "month").format("MMM")}
              </div>
              <div className="mx-2 text-p-500">
                {momentDatetime.format("MMM")}
              </div>
              <div className="mx-2" onClick={() => changeMonth(1)}>
                {moment(parseInt(timestamp) * 1000).tz(timezone).add(1, "month").format("MMM")}
              </div>
              <div className="mx-2" onClick={() => changeMonth(2)}>
                {moment(parseInt(timestamp) * 1000).tz(timezone).add(2, "month").format("MMM")}
              </div>
            </div>
            <img src={rightArrow} className="mx-1 cursor-pointer" alt="next" onClick={() => changeMonth(1)}/>
          </div>

          <table className="w-max mx-auto font-light mb-5 text-xs text-center">
            <thead className="font-light">
              <tr className="font-light">
                {["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map(
                  d => <th className="font-light text-p-300" key={d}>{d}</th>
                )}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, weekNum) => {
                return (
                  <tr key={weekNum}>{week.map((day, dayNum) => {
                    return (
                      <td
                        key={dayNum}
                        className={`cursor-pointer w-6 h-6 rounded ${day === momentDatetime.date() ? "bg-p-500 text-white" : ""}`}
                        onClick={daySelected}
                      >{day}</td>
                    )
                  })}</tr>
                )
              })}
            </tbody>
          </table>

          <div className="bg-gray-200 text-p-300 text-lg mb-4 w-max mx-auto rounded">
            <input
              className="w-7 p-0 text-right text-p-500 font-medium"
              type="number"
              value={hourText !== null ? hourText : momentDatetime.format("HH")}
              onBlur={hourBlur}
              onChange={hourChanged}
              onFocus={() => setEditingText(true)}
            />
            <span>:</span>
            <input
              className="w-7 p-0 text-left text-p-500 font-medium"
              type="number"
              value={minuteText !== null ? minuteText : momentDatetime.format("mm")}
              onBlur={minuteBlur}
              onChange={minuteChanged}
              onFocus={() => setEditingText(true)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

DatetimePicker.propTypes = {
  timestamp: PropTypes.number.isRequired,
  setTimestamp: PropTypes.func.isRequired,
  center: PropTypes.bool
};

export default DatetimePicker;