import React, { useRef } from "react";
import PropTypes from "prop-types";

const Modal = props => {

  const { title, subtitle, text, children, setShowModal, removePadding } = props;
  const hasText = title || subtitle || text;
  const boxRef = useRef();

  const dismiss = e => {
    if (!boxRef.current.contains(e.target)) {
      setShowModal(false);
    }
  }

  return (
    <div onClick={dismiss} className="fixed left-0 top-0 w-full h-full flex items-center justify-center bg-gray-tint-500 z-50">
      <div ref={boxRef} className={`bg-white rounded-md max-w-md overflow-hidden ${removePadding ? "p-0" : "px-7 py-6"}`}>
        {title && <div className="text-3xl leading-2">{title}</div>}
        {subtitle && <div className="text-p-400 font-medium mt-1">{subtitle}</div>}
        {text && <div className="text-sm font-light mt-2">{text}</div>}
        <div className={hasText ? "mt-6" : ""}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  setShowModal: PropTypes.func.isRequired,
  removePadding: PropTypes.bool
};

export default Modal;