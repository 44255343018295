import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useDocumentTitle } from "../hooks";
import Base from "./Base";
import { PROJECTS } from "../queries";
import Project from "../components/Project";

const ProjectsPage = () => {

  useDocumentTitle("Projects - LabTab");

  const { loading, data } = useQuery(PROJECTS);

  if (loading) return <Base loading={true} />

  const projects = data.user.projects;

  if (!projects.length) {
    return (
      <Base>
        <div className="no-data">
          You haven't added any projects yet - <br />would you like
          to <Link to="/projects/new/"> add one</Link>?
        </div>
      </Base>
    )
  }

  return (
    <Base>
      <Link className="btn-primary block w-max mb-4 py-2" to="/projects/new/">New Project</Link>
      <div className="grid gap-4 sm:grid-cols-80">
        {projects.map(project => <Project project={project} key={project.id} isLink={true} />)}
      </div>
    </Base>
  );
};

ProjectsPage.propTypes = {
  
};

export default ProjectsPage;