import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MemberPhoto from "./MemberPhoto";
import MetSummary from "./MetSummary";

const Member = props => {

  const { member } = props;

  return (
    <Link to={`/members/${member.id}/`} className={`block bg-white rounded-md p-5 w-full filter shadow-md hover:bg-gray-100 ${props.className || ""}`}>
      <MemberPhoto member={member} size={20} sm={24}>
        <div className="text-2xl sm:text-3xl font-medium">{member.name}</div>
        <div className="text-md sm:text-lg">{member.position}</div>
        <MetSummary member={member} className="text-sm" />
      </MemberPhoto>
    </Link>
  );
};

Member.propTypes = {
  member: PropTypes.object.isRequired
};

export default Member;