import React, { useContext, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import arrowIcon from "../images/arrow.svg"
import settingsIcon from "../images/settings.svg"
import logoutIcon from "../images/log-out.svg"
import { UserContext } from "../contexts";
import { TOKEN } from "../queries";
import { LOGOUT } from "../mutations";
import logo from "../images/logo.svg"
import SearchBar from "./SearchBar";

const Nav = props => {
  
  const { menuDown, setMenuDown } = props;
  const colors = require("../colors").colors;
  const [user, setUser] = useContext(UserContext);
  const dropdownElement = useRef(null);
  const mobileDropdownElement = useRef(null);
  const history = useHistory();
  const client = useApolloClient();

  const [logout,] = useMutation(LOGOUT, {
    onCompleted: () => {
      setUser(false);
      client.cache.writeQuery({query: TOKEN, data: {accessToken: null}});
      client.cache.reset();
      history.push("/");
    }
  });

  const clickOutside = e => {
    if (dropdownElement.current && !dropdownElement.current.contains(e.target)) {
      if (mobileDropdownElement.current && !mobileDropdownElement.current.contains(e.target)) {
        setMenuDown(false);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside);
    return () => document.removeEventListener("click", clickOutside);
  });

  return (
    <nav className="bg-p-500 z-50 text-white h-14 flex items-center justify-between px-5 sm:areas-nav sm:bg-white sm:text-gray-800">
      <Link to="/" className="h-3/5 block sm:hidden">
        <img src={logo} alt="labtab" className="h-full" />
      </Link>

      <SearchBar />

      <svg ref={mobileDropdownElement} width="26" height="27" viewBox="8 0 26 27" onClick={() => setMenuDown(!menuDown)} className="h-1/3 cursor-pointer sm:hidden">
        <line x1={menuDown ? 8.5 : 2.5} y1="2.5" x2="38.5" y2="2.5" stroke={colors.p[100]} strokeWidth="5" strokeLinecap="round" />
        <line x1={menuDown ? 2.5 : 8.5} y1="13.5" x2="38.5" y2="13.5" stroke={colors.p[100]} strokeWidth="5" strokeLinecap="round"/>
        <line x1={menuDown ? 8.5 : 2.5} y1="24.5" x2="38.5" y2="24.5" stroke={colors.p[100]} strokeWidth="5" strokeLinecap="round"/>
      </svg>

      <div className="hidden sm:flex" ref={dropdownElement}>
        <div className="text-sm">{user.name}</div>
        <img src={arrowIcon} alt="toggle" onClick={() => setMenuDown(!menuDown)} className={`block w-3 ml-4 cursor-pointer ${menuDown ? "transform rotate-180" : ""}`} />
        <div className={`${menuDown ? "block" : "hidden"} rounded overflow-hidden absolute top-16 right-3 bg-white text-sm filter drop-shadow`}>
          <Link to="/settings/" className="flex align-center py-3 px-4 pr-8 hover:bg-gray-100">
            <img src={settingsIcon} alt="" className="w-5 mr-3" />Settings
          </Link>
          <div onClick={logout} className="flex align-center py-3 px-4 cursor-pointer pr-8 hover:bg-gray-100">
            <img src={logoutIcon} alt="" className="w-5 mr-3" />Log Out
          </div>
        </div>
      </div>
    </nav>
  );
};

Nav.propTypes = {
  menuDown: PropTypes.bool,
  setMenuDown: PropTypes.func
};

export default Nav;