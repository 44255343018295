import React from "react";
import PropTypes from "prop-types";
import Meeting from "./Meeting";
import MemberPhoto from "./MemberPhoto";
const colors = require("../colors").colors;

const MeetingsTimeline = props => {

  const { meetings, member, startTimestamp, endTimestamp, pixelsPerDay } = props;

  const timelineHeight = 6;
  const tickHeight = 12;
  const tickWidth = 6;
  const meetingWidth = 400;
  const minMeetingSpacing = 10;
  const timelineColor = colors.gray[100];

  const timeSpan = startTimestamp - endTimestamp;
  const timelineWidth = (timeSpan / 86400) * pixelsPerDay;

  const topRowMeetings = meetings.filter((_, index) => index % 2 === 0);
  const bottomRowMeetings = meetings.filter((_, index) => index % 2 !== 0);

  const lastMeetingId = meetings.length && meetings[meetings.length - 1].id;

  const timestampToPosition = timestamp => {
    return ((startTimestamp - timestamp) / timeSpan) * timelineWidth
  }

  const timelineXs = meetings.reduce((prev, curr) => ({...prev, [curr.id]: (
    timestampToPosition(curr.timestamp)
  )}), {});

  const rowToObj = meetings => {
    let prevEnd = 0;
    return meetings.reduce((prev, meeting) => {
      const idealX = timestampToPosition(meeting.timestamp);
      const actualX = (
        idealX <= prevEnd + minMeetingSpacing && prevEnd !== 0
       ) ? prevEnd + minMeetingSpacing : idealX;
      const margin = actualX - prevEnd;
      prevEnd = actualX + meetingWidth;
      return ({
        ...prev, [meeting.id]: [margin, actualX]
      })
    }, {})
  }

  const meetingXs = {...rowToObj(topRowMeetings), ...rowToObj(bottomRowMeetings)};

  return (
    <div className="flex items-center">
      {member && (
        <div className="mr-5">
          <MemberPhoto member={member} size={36} isLink={true} />
          <div className="text-center text-lg mt-2">{member.name}</div>
        </div>
      )}
      <div>
        <div className={`relative flex w-max items-end`}>
          {topRowMeetings.map(meeting => (
            <Meeting meeting={meeting} key={meeting.id} showMember={!member} style={{
              width: meetingWidth,
              marginLeft: meetingXs[meeting.id][0],
              height: "fit-content"
            }} />
          ))}
        </div>

        <svg
          width={Math.max(...Object.values(meetingXs).map(x => x[1])) + tickWidth}
          height={timelineHeight + (2 * tickHeight)}
          className="ml-3"
        >
          <rect
            x={0}
            y={tickHeight}
            height={timelineHeight}
            width={timelineWidth + tickWidth}
            fill={timelineColor}
          />
          {meetings.map((meeting, index) => {
            const onTop = index % 2 === 0;
            return (
              <React.Fragment key={meeting.id}>
                <rect
                  x={timelineXs[meeting.id]}
                  y={onTop ? tickHeight / 2 : tickHeight + timelineHeight}
                  height={tickHeight / 2}
                  width={tickWidth}
                  fill={timelineColor}
                />
                <rect
                  x={meetingXs[meeting.id][1]}
                  y={onTop ? 0 : (1.5 * tickHeight) + timelineHeight}
                  height={tickHeight / 2}
                  width={tickWidth}
                  fill={timelineColor}
                />
                <rect
                  x={timelineXs[meeting.id]}
                  y={onTop ? (tickHeight * 0.5) - (tickWidth * 0.5) : (1.5 * tickHeight) + timelineHeight - (tickWidth * 0.5)}
                  height={tickWidth}
                  width={meetingXs[meeting.id][1] - timelineXs[meeting.id] + tickWidth}
                  fill={timelineColor}
                />
              </React.Fragment>
            )
          })}
        </svg>

        <div className={`relative flex w-max items-start`}>
          {bottomRowMeetings.map(meeting => (
            <Meeting meeting={meeting} key={meeting.id} showMember={!member} style={{
              width: meetingWidth,
              marginLeft: meetingXs[meeting.id][0],
              height: "fit-content"
            }} className={meeting.id === lastMeetingId ? "mr-5" : ""}/>
          ))}
        </div>
      </div>
    </div>
  );
};

MeetingsTimeline.propTypes = {
  meetings: PropTypes.array.isRequired,
  member: PropTypes.object,
  startTimestamp: PropTypes.number.isRequired,
  endTimestamp: PropTypes.number.isRequired,
  pixelsPerDay: PropTypes.number.isRequired
};

export default MeetingsTimeline;