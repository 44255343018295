import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import BinaryToggle from "../components/BinaryToggle";

const MeetingsOptions = props => {

  const { 
    perMember, setPerMember, showPerMember, timelineView,
    setTimelineView, showTimelineView, future
  } = props;

  const selectedStyle = "text-p-500 border-b-4 border-p-500 pb-1"

  return (
    <div className="flex flex-col items-center mb-10 lg:h-10 lg:flex-row lg:items-center lg:justify-between">
      <div className="flex flex-col-reverse lg:flex-row lg:items-center">
        <div className="mb-6 grid gap-2 lg:mb-0 lg:w-64">
          {showPerMember && <BinaryToggle
            trueText={"Per Member"}
            falseText={"All Members"}
            value={perMember}
            setValue={setPerMember}
          />}
          {showTimelineView && <BinaryToggle
            trueText={"Timeline View"}
            falseText={"List View"}
            value={timelineView}
            setValue={setTimelineView}
          />}
        </div>
        <div className="text-gray-600 font-medium mb-6 lg:ml-16 lg:mb-0 xl:ml-64">
          <Link to="/meetings/" className={future ? "" : selectedStyle}>Past Meetings</Link>
          <span className="px-1"> | </span>
          <Link to="/meetings/future/" className={future ? selectedStyle : ""}>Future Meetings</Link>
        </div>
      </div>
     
      <Link to="/meetings/new/" className="btn-primary w-max sm:mt-0 lg:ml-16">New Meeting</Link>
    </div>
  );
};

MeetingsOptions.propTypes = {
  perMember: PropTypes.bool.isRequired,
  setPerMember: PropTypes.func.isRequired,
  showPerMember: PropTypes.bool.isRequired,
  timelineView: PropTypes.bool.isRequired,
  setTimelineView: PropTypes.func.isRequired,
  showTimelineView: PropTypes.bool.isRequired,
  future: PropTypes.bool.isRequired,
};

export default MeetingsOptions;