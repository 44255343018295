import React from "react";
import Div100vh from "react-div-100vh";
import { useDocumentTitle } from "../hooks";
import LoginForm from "../components/LoginForm";

const LoginPage = () => {

  useDocumentTitle("Login - LabTab");

  return (
    <Div100vh className="bg-p-200 flex items-center justify-center">
      <LoginForm />
    </Div100vh>
  );
};

LoginPage.propTypes = {
  
};

export default LoginPage;