import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const ProjectLink = props => {

  const { project, reversed } = props;

  return (
    <Link
      to={`/projects/${project.id}/`}
      key={project.id}
      className={`inline-grid grid-cols-max items-center gap-2 hover:bg-gray-100 rounded px-2 py-1 ${reversed ? "ml-auto" : ""} ${props.className || ""}`}
    >
      {reversed && <div className="text-gray-800">{project.name}</div>}
      <div style={{backgroundColor: project.color}} className="w-5 h-5 rounded-full" />
      {!reversed && <div className="text-gray-800">{project.name}</div>}
    </Link>
  );
};

ProjectLink.propTypes = {
  project: PropTypes.object.isRequired,
  reversed: PropTypes.bool
};

export default ProjectLink;