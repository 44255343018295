import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { DELETE_MEETING } from "../mutations";
import { MEETINGS, MEMBERS, PROJECTS } from "../queries";
import Modal from "./Modal";
import Button from "./Button";

const DeleteMeetingModal = props => {

  const { showModal, setShowModal, meeting } = props;
  const history = useHistory();

  const [deleteMeeting, deleteMeetingMutation] = useMutation(DELETE_MEETING, {
    onCompleted: () => {
      history.push("/meetings/");
    },
    awaitRefetchQueries: true,
    refetchQueries: [{query: MEETINGS}, {query: MEMBERS}, {query: PROJECTS}]
  })

  if (!showModal) return <div />

  return (
    <Modal
      title="Delete Meeting?"
      subtitle={meeting.title}
      text="Are you sure you want to delete this meeting? This can't be undone."
      setShowModal={setShowModal}
    >
      <Button className="btn-primary" onClick={() => deleteMeeting({variables: {id: meeting.id}})} loading={deleteMeetingMutation.loading}>Delete</Button>
      <button className="btn-secondary ml-3" onClick={() => setShowModal(false)}>Keep</button>
    </Modal>
  );
};

DeleteMeetingModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  meeting: PropTypes.object.isRequired
};

export default DeleteMeetingModal;