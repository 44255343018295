import React, { useState, useContext } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { TOKEN } from "../queries";
import { LOGIN } from "../mutations";
import { UserContext } from "../contexts";
import { createErrorObject } from "../forms";
import logo from "../images/logo-text-color.svg"
import Button from "./Button";

const LoginForm = props => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [,setUser] = useContext(UserContext);
  const history = useHistory();
  const client = useApolloClient();

  const [login, loginMutation] = useMutation(LOGIN, {
    onCompleted: data => {
      setUser(data.login.user);
      client.cache.writeQuery({
        query: TOKEN, data: {accessToken: data.login.accessToken}
      });
      history.push("/");
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors))
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    login({variables: {email, password}});
  }

  return (
    <form onSubmit={formSubmit} className={`bg-white rounded-md max-w-md mx-4 my-4 p-6 filter w-full shadow-md ${props.className || ""}`}>
      <Link to="/" className="block w-max mx-auto mb-6"><img src={logo} alt="labtab" /></Link>
      {errors.general && <div className="error">There was an error.</div>}
  
      <div className="mb-4">
        <label htmlFor="email">email</label>
        <div>
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={errors.email ? "border-2 border-error-200" : ""}
            autoComplete="email"
            required
          />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="password">password</label>
        <div>
          <input
            type="password"
            id="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={errors.email ? "border-2 border-error-200" : ""}
            autoComplete="new-password"
            required
          />
          {errors.password && <div className="error">{errors.password}</div>}
        </div>
      </div>

      <Button className="btn-primary py-2" type="submit" loading={loginMutation.loading}>Sign In</Button>
    </form>
  );
};

LoginForm.propTypes = {
  
};

export default LoginForm;