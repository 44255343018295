import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER } from "../mutations";
import { UserContext } from "../contexts";
import { createErrorObject } from "../forms";
import CornerMessage from "./CornerMessage";
import Button from "./Button";

const UpdateEmailForm = props => {

  const [user, setUser] = useContext(UserContext);
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);

  const [updateUser, updateUserMutation] = useMutation(UPDATE_USER, {
    onCompleted: data => {
      setUser(data.updateUser.user);
      setErrors({});
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 5000);
    },
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors))
    }
  });

  const onSubmit = e => {
    e.preventDefault();
    updateUser({variables: {email: email || user.email, name: user.name}});
  }

  return (
    <form onSubmit={onSubmit} className={`mb-8 lg:w-80 ${props.className || ""}`}>
      <div>
        <label htmlFor="email">Email</label>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            value={email === null ? user.email : email}
            onChange={e => setEmail(e.target.value)}
            autoComplete="email"
            className={`${errors.email ? "border-2 border-error-200" : ""}`}
            required
            />
          {errors.email && <div className="error">{errors.email}</div>}
        </div>
      </div>
      <Button className="btn-primary w-full py-2" type="submit" loading={updateUserMutation.loading}>Update Email</Button>
      {showSuccess && <CornerMessage ttl={4000}>Email successfully updated</CornerMessage>}
    </form>
  );
};

UpdateEmailForm.propTypes = {
  
};

export default UpdateEmailForm;