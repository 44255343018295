import React, { useState } from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import MoonLoader from "react-spinners/MoonLoader";
import Nav from "../components/Nav";
import Sidebar from "../components/Sidebar";

const Base = props => {

  const { loading, center, children } = props;
  const [menuDown, setMenuDown] = useState(false);
  const colors = require("../colors").colors;
  
  return (
    <Div100vh className="grid grid-rows-mob-base sm:grid-cols-base">
      <Nav menuDown={menuDown} setMenuDown={setMenuDown} />
      <Sidebar menuDown={menuDown} />
      <main className={`p-5 bg-gray-tint-100 ${center ? "flex justify-center center" : ""} overflow-scroll sm:main ${loading ? "flex justify-center items-center" : ""}`}>
        {loading ? <MoonLoader color={colors.p[500]} /> : children}
      </main>
    </Div100vh>
  );
};

Base.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default Base;