import React from "react";
import { useQuery } from "@apollo/client";
import Base from "./Base";
import MemberForm from "../components/MemberForm";
import { useDocumentTitle } from "../hooks";
import { POSSIBLE_PROJECTS } from "../queries";

const NewMemberPage = () => {

  useDocumentTitle("New Lab Member - LabTab");

  const { loading, data } = useQuery(POSSIBLE_PROJECTS);

  if (loading ) return <Base loading={true} />

  const projects = data.user.projects;

  return (
    <Base center={true}>
      <MemberForm allProjects={projects} />
    </Base>
  );
};

NewMemberPage.propTypes = {
  
};

export default NewMemberPage;